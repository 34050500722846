import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import SlideOver from "~/components/SlideOver";
import { ListAllFirestationsDocument } from "~/gql/graphql";
import { CreateFirestationMutationDocument } from "~/mutations/CreateFirestation.mutation";
import { extractCode } from "~/util/error";

type Inputs = {
  name: string;
};

export default function CreateFirestation() {
  const [createFirestation, { data, error }] = useMutation(
    CreateFirestationMutationDocument,
    {
      refetchQueries: [ListAllFirestationsDocument]
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      const code = extractCode(error);
      switch (code) {
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, eine neue Feuerwehr anzulegen."
          );
          break;
        default:
          toast.error("Unerwarteter Fehler beim Anlegen der Feuerwehr.");
          break;
      }
    } else if (data) {
      navigate(`/firestations/${data.firestationCreate.firestation?.id}`);
    }
  }, [data, error]);

  const { register, handleSubmit } = useForm<Inputs>();

  const submitHandler: SubmitHandler<Inputs> = (formData) =>
    createFirestation({ variables: { input: formData } });

  return (
    <SlideOver title="Neue Feuerwehr anlegen">
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name (bitte ohne "Feuerwehr"){" "}
                  <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    required
                    placeholder='z.B. "Musterhausen"'
                    {...register("name")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Feuerwehr anlegen
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
