/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  mutation AcceptInvitation($input: InvitationAcceptInput!) {\n    invitationAccept(input: $input) {\n      acceptedInvitationId\n    }\n  }\n": types.AcceptInvitationDocument,
    "\n  mutation CreateExamination($input: ExaminationCreateInput!) {\n    examinationCreate(input: $input) {\n      examination {\n        id\n      }\n    }\n  }\n": types.CreateExaminationDocument,
    "\n  mutation CreateFirefighter($input: FirefighterCreateInput!) {\n    firefighterCreate(input: $input) {\n      firefighter {\n        id\n      }\n    }\n  }\n": types.CreateFirefighterDocument,
    "\n  mutation CreateFirestation($input: FirestationCreateInput!) {\n    firestationCreate(input: $input) {\n      firestation {\n        id\n      }\n    }\n  }\n": types.CreateFirestationDocument,
    "\n  mutation CreateFirestationMembership(\n    $input: FirestationMembershipCreateInput!\n  ) {\n    firestationMembershipCreate(input: $input) {\n      firestationMembership {\n        firefighter {\n          id\n        }\n      }\n    }\n  }\n": types.CreateFirestationMembershipDocument,
    "\n  mutation CreateGroup($input: GroupCreateInput!) {\n    groupCreate(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n": types.CreateGroupDocument,
    "\n  mutation CreateGroupMembership($input: GroupMembershipCreateInput!) {\n    groupMembershipCreate(input: $input) {\n      groupMembership {\n        group {\n          id\n        }\n      }\n    }\n  }\n": types.CreateGroupMembershipDocument,
    "\n  mutation CreateInvitation($input: InvitationCreateInput!) {\n    invitationCreate(input: $input) {\n      invitation {\n        id\n      }\n    }\n  }\n": types.CreateInvitationDocument,
    "\n  mutation CreateOrganization($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        id\n      }\n    }\n  }\n": types.CreateOrganizationDocument,
    "\n  mutation CreateScbaSession($input: ScbaSessionCreateInput!) {\n    scbaSessionCreate(input: $input) {\n      scbaSession {\n        id\n      }\n    }\n  }\n": types.CreateScbaSessionDocument,
    "\n  mutation CreateScbaSessionParticipation(\n    $input: ScbaSessionParticipationCreateInput!\n  ) {\n    scbaSessionParticipationCreate(input: $input) {\n      scbaSessionParticipation {\n        initialPressure\n      }\n    }\n  }\n": types.CreateScbaSessionParticipationDocument,
    "\n  mutation DeleteExamination($input: ExaminationDeleteInput!) {\n    examinationDelete(input: $input) {\n      deletedExaminationId\n    }\n  }\n": types.DeleteExaminationDocument,
    "\n  mutation DeleteFirefighter($input: FirefighterDeleteInput!) {\n    firefighterDelete(input: $input) {\n      deletedFirefighterId\n    }\n  }\n": types.DeleteFirefighterDocument,
    "\n  mutation DeleteFirestation($input: FirestationDeleteInput!) {\n    firestationDelete(input: $input) {\n      deletedFirestationId\n    }\n  }\n": types.DeleteFirestationDocument,
    "\n  mutation DeleteFirestationMembership(\n    $input: FirestationMembershipDeleteInput!\n  ) {\n    firestationMembershipDelete(input: $input) {\n      deletedFirestationMembershipFirefighterId\n    }\n  }\n": types.DeleteFirestationMembershipDocument,
    "\n  mutation DeleteGroup($input: GroupDeleteInput!) {\n    groupDelete(input: $input) {\n      deletedGroupId\n    }\n  }\n": types.DeleteGroupDocument,
    "\n  mutation DeleteGroupMembership($input: GroupMembershipDeleteInput!) {\n    groupMembershipDelete(input: $input) {\n      deletedGroupMembershipGroupId\n    }\n  }\n": types.DeleteGroupMembershipDocument,
    "\n  mutation DeleteScbaSession($input: ScbaSessionDeleteInput!) {\n    scbaSessionDelete(input: $input) {\n      deletedScbaSessionId\n    }\n  }\n": types.DeleteScbaSessionDocument,
    "\n  mutation DeleteScbaSessionParticipation(\n    $input: ScbaSessionParticipationDeleteInput!\n  ) {\n    scbaSessionParticipationDelete(input: $input) {\n      deletedScbaSessionParticipationScbaSessionId\n    }\n  }\n": types.DeleteScbaSessionParticipationDocument,
    "\n  mutation DeleteUser($input: UserDeleteInput!) {\n    userDelete(input: $input) {\n      deletedUserId\n    }\n  }\n": types.DeleteUserDocument,
    "\n  mutation UpdateExamination($input: ExaminationUpdateInput!) {\n    examinationUpdate(input: $input) {\n      examination {\n        id\n      }\n    }\n  }\n": types.UpdateExaminationDocument,
    "\n  mutation UpdateFirefighter($input: FirefighterUpdateInput!) {\n    firefighterUpdate(input: $input) {\n      firefighter {\n        id\n      }\n    }\n  }\n": types.UpdateFirefighterDocument,
    "\n  mutation UpdateFirestation($input: FirestationUpdateInput!) {\n    firestationUpdate(input: $input) {\n      firestation {\n        id\n      }\n    }\n  }\n": types.UpdateFirestationDocument,
    "\n  mutation UpdateGroup($input: GroupUpdateInput!) {\n    groupUpdate(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n": types.UpdateGroupDocument,
    "\n  mutation UpdateScbaSession($input: ScbaSessionUpdateInput!) {\n    scbaSessionUpdate(input: $input) {\n      scbaSession {\n        id\n      }\n    }\n  }\n": types.UpdateScbaSessionDocument,
    "\n  mutation UpdateScbaSessionParticipation(\n    $input: ScbaSessionParticipationUpdateInput!\n  ) {\n    scbaSessionParticipationUpdate(input: $input) {\n      scbaSessionParticipation {\n        initialPressure\n      }\n    }\n  }\n": types.UpdateScbaSessionParticipationDocument,
    "\n  mutation UpdateScbaSessionParticipationsBulk(\n    $input: ScbaSessionParticipationsUpdateBulkInput!\n  ) {\n    scbaSessionParticipationsUpdateBulk(input: $input) {\n      scbaSessionId\n    }\n  }\n": types.UpdateScbaSessionParticipationsBulkDocument,
    "\n  query AddFirefighterToGroup($groupId: ID!) {\n    group(id: $groupId) {\n      members {\n        id\n      }\n    }\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n    }\n  }\n": types.AddFirefighterToGroupDocument,
    "\n  query AddFirefighterToScbaSession($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      participations {\n        firefighter {\n          id\n        }\n      }\n    }\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n    }\n  }\n": types.AddFirefighterToScbaSessionDocument,
    "\n  query AddFirestationMembership($firefighterId: ID!) {\n    firestations {\n      id\n      name\n    }\n    firefighter(id: $firefighterId) {\n      firestations {\n        id\n      }\n    }\n  }\n": types.AddFirestationMembershipDocument,
    "\n  query ExaminationDetail($examinationId: ID!) {\n    examination(id: $examinationId) {\n      examinedAt\n      validUntil\n      type\n    }\n  }\n": types.ExaminationDetailDocument,
    "\n  query FirefighterDetail($firefighterId: ID!) {\n    firefighter(id: $firefighterId) {\n      id\n      lastName\n      firstName\n      dateOfBirth\n      scbaSessionParticipations {\n        initialPressure\n        endPressure\n        scbaSession {\n          id\n          date\n        }\n      }\n      examinations {\n        id\n        type\n        validUntil\n        examinedAt\n      }\n      firestations {\n        id\n        name\n      }\n      barcodes {\n        id\n        code\n        createdAt\n        lastUsedAt\n      }\n    }\n  }\n": types.FirefighterDetailDocument,
    "\n  query FirefighterDetailSimple($firefighterId: ID!) {\n    firefighter(id: $firefighterId) {\n      lastName\n      firstName\n    }\n  }\n": types.FirefighterDetailSimpleDocument,
    "\n  query FirestationDetail(\n    $firestationId: ID!\n    $examinationType: ExaminationType!\n  ) {\n    firestation(id: $firestationId) {\n      id\n      name\n      numberOfFirefighters\n      numberOfDeployableFirefighters\n      numberOfCompletedScbaSessionsThisYear\n      firefighters {\n        id\n        firstName\n        lastName\n        firestations {\n          name\n        }\n        lastScbaSessionParticipation {\n          scbaSession {\n            date\n          }\n        }\n        hasValidExamination(type: $examinationType)\n      }\n      responsible {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n": types.FirestationDetailDocument,
    "\n  query FirestationDetailSimple($firestationId: ID!) {\n    firestation(id: $firestationId) {\n      name\n    }\n  }\n": types.FirestationDetailSimpleDocument,
    "\n  query GroupDetail($groupId: ID!) {\n    group(id: $groupId) {\n      name\n      members {\n        id\n        firstName\n        lastName\n        firestations {\n          name\n        }\n      }\n    }\n  }\n": types.GroupDetailDocument,
    "\n  query GroupDetailSimple($groupId: ID!) {\n    group(id: $groupId) {\n      name\n    }\n  }\n": types.GroupDetailSimpleDocument,
    "\n  query InvitationDetail($invitationId: ID!) {\n    invitation(id: $invitationId) {\n      organization {\n        name\n      }\n    }\n  }\n": types.InvitationDetailDocument,
    "\n  query ListAllFirefighters($examinationType: ExaminationType!) {\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n      lastScbaSessionParticipation {\n        scbaSession {\n          date\n        }\n      }\n      hasValidExamination(type: $examinationType)\n    }\n  }\n": types.ListAllFirefightersDocument,
    "\n  query ListAllFirestations {\n    firestations {\n      id\n      name\n      numberOfFirefighters\n    }\n  }\n": types.ListAllFirestationsDocument,
    "\n  query ListAllGroups {\n    groups {\n      id\n      name\n      numberOfMembers\n    }\n  }\n": types.ListAllGroupsDocument,
    "\n  query ListAllScbaSessions {\n    scbaSessions {\n      id\n      date\n      numberOfParticipations\n    }\n  }\n": types.ListAllScbaSessionsDocument,
    "\n  query OrganizationDetail {\n    organization {\n      name\n    }\n    users {\n      id\n      name\n      roles\n      email\n    }\n  }\n": types.OrganizationDetailDocument,
    "\n  query ScbaSessionDetail($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      id\n      comment\n      date\n      participations {\n        initialPressure\n        endPressure\n        firefighter {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n": types.ScbaSessionDetailDocument,
    "\n  query ScbaSessionDetailSimple($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      date\n      comment\n    }\n  }\n": types.ScbaSessionDetailSimpleDocument,
    "\n  query ScbaSessionParticipationDetail(\n    $scbaSessionId: ID!\n    $firefighterId: ID!\n  ) {\n    scbaSessionParticipation(\n      scbaSessionId: $scbaSessionId\n      firefighterId: $firefighterId\n    ) {\n      initialPressure\n      endPressure\n    }\n  }\n": types.ScbaSessionParticipationDetailDocument,
    "\n  query ScbaSessionParticipationDetailSimple(\n    $scbaSessionId: ID!\n    $firefighterId: ID!\n  ) {\n    scbaSessionParticipation(\n      scbaSessionId: $scbaSessionId\n      firefighterId: $firefighterId\n    ) {\n      scbaSession {\n        date\n      }\n    }\n  }\n": types.ScbaSessionParticipationDetailSimpleDocument,
    "\n  query UserDetail($userId: ID!) {\n    user(id: $userId) {\n      email\n      name\n      roles\n    }\n  }\n": types.UserDetailDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation AcceptInvitation($input: InvitationAcceptInput!) {\n    invitationAccept(input: $input) {\n      acceptedInvitationId\n    }\n  }\n"): (typeof documents)["\n  mutation AcceptInvitation($input: InvitationAcceptInput!) {\n    invitationAccept(input: $input) {\n      acceptedInvitationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateExamination($input: ExaminationCreateInput!) {\n    examinationCreate(input: $input) {\n      examination {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateExamination($input: ExaminationCreateInput!) {\n    examinationCreate(input: $input) {\n      examination {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFirefighter($input: FirefighterCreateInput!) {\n    firefighterCreate(input: $input) {\n      firefighter {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFirefighter($input: FirefighterCreateInput!) {\n    firefighterCreate(input: $input) {\n      firefighter {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFirestation($input: FirestationCreateInput!) {\n    firestationCreate(input: $input) {\n      firestation {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFirestation($input: FirestationCreateInput!) {\n    firestationCreate(input: $input) {\n      firestation {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateFirestationMembership(\n    $input: FirestationMembershipCreateInput!\n  ) {\n    firestationMembershipCreate(input: $input) {\n      firestationMembership {\n        firefighter {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateFirestationMembership(\n    $input: FirestationMembershipCreateInput!\n  ) {\n    firestationMembershipCreate(input: $input) {\n      firestationMembership {\n        firefighter {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateGroup($input: GroupCreateInput!) {\n    groupCreate(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGroup($input: GroupCreateInput!) {\n    groupCreate(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateGroupMembership($input: GroupMembershipCreateInput!) {\n    groupMembershipCreate(input: $input) {\n      groupMembership {\n        group {\n          id\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateGroupMembership($input: GroupMembershipCreateInput!) {\n    groupMembershipCreate(input: $input) {\n      groupMembership {\n        group {\n          id\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateInvitation($input: InvitationCreateInput!) {\n    invitationCreate(input: $input) {\n      invitation {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateInvitation($input: InvitationCreateInput!) {\n    invitationCreate(input: $input) {\n      invitation {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateOrganization($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateOrganization($input: OrganizationCreateInput!) {\n    organizationCreate(input: $input) {\n      organization {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateScbaSession($input: ScbaSessionCreateInput!) {\n    scbaSessionCreate(input: $input) {\n      scbaSession {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateScbaSession($input: ScbaSessionCreateInput!) {\n    scbaSessionCreate(input: $input) {\n      scbaSession {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation CreateScbaSessionParticipation(\n    $input: ScbaSessionParticipationCreateInput!\n  ) {\n    scbaSessionParticipationCreate(input: $input) {\n      scbaSessionParticipation {\n        initialPressure\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation CreateScbaSessionParticipation(\n    $input: ScbaSessionParticipationCreateInput!\n  ) {\n    scbaSessionParticipationCreate(input: $input) {\n      scbaSessionParticipation {\n        initialPressure\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteExamination($input: ExaminationDeleteInput!) {\n    examinationDelete(input: $input) {\n      deletedExaminationId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteExamination($input: ExaminationDeleteInput!) {\n    examinationDelete(input: $input) {\n      deletedExaminationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteFirefighter($input: FirefighterDeleteInput!) {\n    firefighterDelete(input: $input) {\n      deletedFirefighterId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteFirefighter($input: FirefighterDeleteInput!) {\n    firefighterDelete(input: $input) {\n      deletedFirefighterId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteFirestation($input: FirestationDeleteInput!) {\n    firestationDelete(input: $input) {\n      deletedFirestationId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteFirestation($input: FirestationDeleteInput!) {\n    firestationDelete(input: $input) {\n      deletedFirestationId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteFirestationMembership(\n    $input: FirestationMembershipDeleteInput!\n  ) {\n    firestationMembershipDelete(input: $input) {\n      deletedFirestationMembershipFirefighterId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteFirestationMembership(\n    $input: FirestationMembershipDeleteInput!\n  ) {\n    firestationMembershipDelete(input: $input) {\n      deletedFirestationMembershipFirefighterId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteGroup($input: GroupDeleteInput!) {\n    groupDelete(input: $input) {\n      deletedGroupId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteGroup($input: GroupDeleteInput!) {\n    groupDelete(input: $input) {\n      deletedGroupId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteGroupMembership($input: GroupMembershipDeleteInput!) {\n    groupMembershipDelete(input: $input) {\n      deletedGroupMembershipGroupId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteGroupMembership($input: GroupMembershipDeleteInput!) {\n    groupMembershipDelete(input: $input) {\n      deletedGroupMembershipGroupId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteScbaSession($input: ScbaSessionDeleteInput!) {\n    scbaSessionDelete(input: $input) {\n      deletedScbaSessionId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteScbaSession($input: ScbaSessionDeleteInput!) {\n    scbaSessionDelete(input: $input) {\n      deletedScbaSessionId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteScbaSessionParticipation(\n    $input: ScbaSessionParticipationDeleteInput!\n  ) {\n    scbaSessionParticipationDelete(input: $input) {\n      deletedScbaSessionParticipationScbaSessionId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteScbaSessionParticipation(\n    $input: ScbaSessionParticipationDeleteInput!\n  ) {\n    scbaSessionParticipationDelete(input: $input) {\n      deletedScbaSessionParticipationScbaSessionId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation DeleteUser($input: UserDeleteInput!) {\n    userDelete(input: $input) {\n      deletedUserId\n    }\n  }\n"): (typeof documents)["\n  mutation DeleteUser($input: UserDeleteInput!) {\n    userDelete(input: $input) {\n      deletedUserId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateExamination($input: ExaminationUpdateInput!) {\n    examinationUpdate(input: $input) {\n      examination {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateExamination($input: ExaminationUpdateInput!) {\n    examinationUpdate(input: $input) {\n      examination {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateFirefighter($input: FirefighterUpdateInput!) {\n    firefighterUpdate(input: $input) {\n      firefighter {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateFirefighter($input: FirefighterUpdateInput!) {\n    firefighterUpdate(input: $input) {\n      firefighter {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateFirestation($input: FirestationUpdateInput!) {\n    firestationUpdate(input: $input) {\n      firestation {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateFirestation($input: FirestationUpdateInput!) {\n    firestationUpdate(input: $input) {\n      firestation {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateGroup($input: GroupUpdateInput!) {\n    groupUpdate(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateGroup($input: GroupUpdateInput!) {\n    groupUpdate(input: $input) {\n      group {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateScbaSession($input: ScbaSessionUpdateInput!) {\n    scbaSessionUpdate(input: $input) {\n      scbaSession {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateScbaSession($input: ScbaSessionUpdateInput!) {\n    scbaSessionUpdate(input: $input) {\n      scbaSession {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateScbaSessionParticipation(\n    $input: ScbaSessionParticipationUpdateInput!\n  ) {\n    scbaSessionParticipationUpdate(input: $input) {\n      scbaSessionParticipation {\n        initialPressure\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateScbaSessionParticipation(\n    $input: ScbaSessionParticipationUpdateInput!\n  ) {\n    scbaSessionParticipationUpdate(input: $input) {\n      scbaSessionParticipation {\n        initialPressure\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation UpdateScbaSessionParticipationsBulk(\n    $input: ScbaSessionParticipationsUpdateBulkInput!\n  ) {\n    scbaSessionParticipationsUpdateBulk(input: $input) {\n      scbaSessionId\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateScbaSessionParticipationsBulk(\n    $input: ScbaSessionParticipationsUpdateBulkInput!\n  ) {\n    scbaSessionParticipationsUpdateBulk(input: $input) {\n      scbaSessionId\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AddFirefighterToGroup($groupId: ID!) {\n    group(id: $groupId) {\n      members {\n        id\n      }\n    }\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AddFirefighterToGroup($groupId: ID!) {\n    group(id: $groupId) {\n      members {\n        id\n      }\n    }\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AddFirefighterToScbaSession($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      participations {\n        firefighter {\n          id\n        }\n      }\n    }\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query AddFirefighterToScbaSession($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      participations {\n        firefighter {\n          id\n        }\n      }\n    }\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query AddFirestationMembership($firefighterId: ID!) {\n    firestations {\n      id\n      name\n    }\n    firefighter(id: $firefighterId) {\n      firestations {\n        id\n      }\n    }\n  }\n"): (typeof documents)["\n  query AddFirestationMembership($firefighterId: ID!) {\n    firestations {\n      id\n      name\n    }\n    firefighter(id: $firefighterId) {\n      firestations {\n        id\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ExaminationDetail($examinationId: ID!) {\n    examination(id: $examinationId) {\n      examinedAt\n      validUntil\n      type\n    }\n  }\n"): (typeof documents)["\n  query ExaminationDetail($examinationId: ID!) {\n    examination(id: $examinationId) {\n      examinedAt\n      validUntil\n      type\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FirefighterDetail($firefighterId: ID!) {\n    firefighter(id: $firefighterId) {\n      id\n      lastName\n      firstName\n      dateOfBirth\n      scbaSessionParticipations {\n        initialPressure\n        endPressure\n        scbaSession {\n          id\n          date\n        }\n      }\n      examinations {\n        id\n        type\n        validUntil\n        examinedAt\n      }\n      firestations {\n        id\n        name\n      }\n      barcodes {\n        id\n        code\n        createdAt\n        lastUsedAt\n      }\n    }\n  }\n"): (typeof documents)["\n  query FirefighterDetail($firefighterId: ID!) {\n    firefighter(id: $firefighterId) {\n      id\n      lastName\n      firstName\n      dateOfBirth\n      scbaSessionParticipations {\n        initialPressure\n        endPressure\n        scbaSession {\n          id\n          date\n        }\n      }\n      examinations {\n        id\n        type\n        validUntil\n        examinedAt\n      }\n      firestations {\n        id\n        name\n      }\n      barcodes {\n        id\n        code\n        createdAt\n        lastUsedAt\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FirefighterDetailSimple($firefighterId: ID!) {\n    firefighter(id: $firefighterId) {\n      lastName\n      firstName\n    }\n  }\n"): (typeof documents)["\n  query FirefighterDetailSimple($firefighterId: ID!) {\n    firefighter(id: $firefighterId) {\n      lastName\n      firstName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FirestationDetail(\n    $firestationId: ID!\n    $examinationType: ExaminationType!\n  ) {\n    firestation(id: $firestationId) {\n      id\n      name\n      numberOfFirefighters\n      numberOfDeployableFirefighters\n      numberOfCompletedScbaSessionsThisYear\n      firefighters {\n        id\n        firstName\n        lastName\n        firestations {\n          name\n        }\n        lastScbaSessionParticipation {\n          scbaSession {\n            date\n          }\n        }\n        hasValidExamination(type: $examinationType)\n      }\n      responsible {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query FirestationDetail(\n    $firestationId: ID!\n    $examinationType: ExaminationType!\n  ) {\n    firestation(id: $firestationId) {\n      id\n      name\n      numberOfFirefighters\n      numberOfDeployableFirefighters\n      numberOfCompletedScbaSessionsThisYear\n      firefighters {\n        id\n        firstName\n        lastName\n        firestations {\n          name\n        }\n        lastScbaSessionParticipation {\n          scbaSession {\n            date\n          }\n        }\n        hasValidExamination(type: $examinationType)\n      }\n      responsible {\n        firstName\n        lastName\n        email\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query FirestationDetailSimple($firestationId: ID!) {\n    firestation(id: $firestationId) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query FirestationDetailSimple($firestationId: ID!) {\n    firestation(id: $firestationId) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GroupDetail($groupId: ID!) {\n    group(id: $groupId) {\n      name\n      members {\n        id\n        firstName\n        lastName\n        firestations {\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GroupDetail($groupId: ID!) {\n    group(id: $groupId) {\n      name\n      members {\n        id\n        firstName\n        lastName\n        firestations {\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GroupDetailSimple($groupId: ID!) {\n    group(id: $groupId) {\n      name\n    }\n  }\n"): (typeof documents)["\n  query GroupDetailSimple($groupId: ID!) {\n    group(id: $groupId) {\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query InvitationDetail($invitationId: ID!) {\n    invitation(id: $invitationId) {\n      organization {\n        name\n      }\n    }\n  }\n"): (typeof documents)["\n  query InvitationDetail($invitationId: ID!) {\n    invitation(id: $invitationId) {\n      organization {\n        name\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListAllFirefighters($examinationType: ExaminationType!) {\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n      lastScbaSessionParticipation {\n        scbaSession {\n          date\n        }\n      }\n      hasValidExamination(type: $examinationType)\n    }\n  }\n"): (typeof documents)["\n  query ListAllFirefighters($examinationType: ExaminationType!) {\n    firefighters {\n      id\n      firstName\n      lastName\n      firestations {\n        name\n      }\n      lastScbaSessionParticipation {\n        scbaSession {\n          date\n        }\n      }\n      hasValidExamination(type: $examinationType)\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListAllFirestations {\n    firestations {\n      id\n      name\n      numberOfFirefighters\n    }\n  }\n"): (typeof documents)["\n  query ListAllFirestations {\n    firestations {\n      id\n      name\n      numberOfFirefighters\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListAllGroups {\n    groups {\n      id\n      name\n      numberOfMembers\n    }\n  }\n"): (typeof documents)["\n  query ListAllGroups {\n    groups {\n      id\n      name\n      numberOfMembers\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ListAllScbaSessions {\n    scbaSessions {\n      id\n      date\n      numberOfParticipations\n    }\n  }\n"): (typeof documents)["\n  query ListAllScbaSessions {\n    scbaSessions {\n      id\n      date\n      numberOfParticipations\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query OrganizationDetail {\n    organization {\n      name\n    }\n    users {\n      id\n      name\n      roles\n      email\n    }\n  }\n"): (typeof documents)["\n  query OrganizationDetail {\n    organization {\n      name\n    }\n    users {\n      id\n      name\n      roles\n      email\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ScbaSessionDetail($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      id\n      comment\n      date\n      participations {\n        initialPressure\n        endPressure\n        firefighter {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query ScbaSessionDetail($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      id\n      comment\n      date\n      participations {\n        initialPressure\n        endPressure\n        firefighter {\n          id\n          firstName\n          lastName\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ScbaSessionDetailSimple($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      date\n      comment\n    }\n  }\n"): (typeof documents)["\n  query ScbaSessionDetailSimple($scbaSessionId: ID!) {\n    scbaSession(id: $scbaSessionId) {\n      date\n      comment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ScbaSessionParticipationDetail(\n    $scbaSessionId: ID!\n    $firefighterId: ID!\n  ) {\n    scbaSessionParticipation(\n      scbaSessionId: $scbaSessionId\n      firefighterId: $firefighterId\n    ) {\n      initialPressure\n      endPressure\n    }\n  }\n"): (typeof documents)["\n  query ScbaSessionParticipationDetail(\n    $scbaSessionId: ID!\n    $firefighterId: ID!\n  ) {\n    scbaSessionParticipation(\n      scbaSessionId: $scbaSessionId\n      firefighterId: $firefighterId\n    ) {\n      initialPressure\n      endPressure\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ScbaSessionParticipationDetailSimple(\n    $scbaSessionId: ID!\n    $firefighterId: ID!\n  ) {\n    scbaSessionParticipation(\n      scbaSessionId: $scbaSessionId\n      firefighterId: $firefighterId\n    ) {\n      scbaSession {\n        date\n      }\n    }\n  }\n"): (typeof documents)["\n  query ScbaSessionParticipationDetailSimple(\n    $scbaSessionId: ID!\n    $firefighterId: ID!\n  ) {\n    scbaSessionParticipation(\n      scbaSessionId: $scbaSessionId\n      firefighterId: $firefighterId\n    ) {\n      scbaSession {\n        date\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query UserDetail($userId: ID!) {\n    user(id: $userId) {\n      email\n      name\n      roles\n    }\n  }\n"): (typeof documents)["\n  query UserDetail($userId: ID!) {\n    user(id: $userId) {\n      email\n      name\n      roles\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;