import { graphql } from "~/gql";

export const CreateInvitationMutationDocument = graphql(`
  mutation CreateInvitation($input: InvitationCreateInput!) {
    invitationCreate(input: $input) {
      invitation {
        id
      }
    }
  }
`);
