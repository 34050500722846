import React from "react";
import { Toaster } from "react-hot-toast";
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements
} from "react-router-dom";

import ForceOnboarding from "~/components/ForceOnboarding";
import GraphQLProvider from "~/components/GraphQLProvider";
import NotFound from "~/components/NotFound";
import Shell from "~/components/Shell";

import Index from "~/routes";
import AccountIndex from "~/routes/account/account";
import DeleteAccount from "~/routes/account/delete";
import FirefighterDetail from "~/routes/firefighters/$firefighterId";
import ShowFirefighterCode from "~/routes/firefighters/$firefighterId.code";
import DeleteFirefighter from "~/routes/firefighters/$firefighterId.delete";
import EditFirefighter from "~/routes/firefighters/$firefighterId.edit";
import DeleteExamination from "~/routes/firefighters/$firefighterId.examinations.$examinationId.delete";
import EditExamination from "~/routes/firefighters/$firefighterId.examinations.$examinationId.edit";
import CreateExamination from "~/routes/firefighters/$firefighterId.examinations.add";
import DeleteFirestationMembership from "~/routes/firefighters/$firefighterId.firestations.$firestationId.delete";
import AddFirefighterToFirestation from "~/routes/firefighters/$firefighterId.firestations.add";
import DeleteScbaSessionParticipations from "~/routes/firefighters/$firefighterId.scbasessions.$scbaSessionId.delete";
import EditScbaSessionParticipation from "~/routes/firefighters/$firefighterId.scbasessions.$scbaSessionId.edit";
import FirefightersIndex from "~/routes/firefighters/firefighters";
import NewFirefighter from "~/routes/firefighters/new";
import FirestationDetail from "~/routes/firestations/$firestationId";
import DeleteFirestation from "~/routes/firestations/$firestationId.delete";
import EditFirestation from "~/routes/firestations/$firestationId.edit";
import FirestationsIndex from "~/routes/firestations/firestations";
import CreateFirestation from "~/routes/firestations/new";
import GroupDetail from "~/routes/groups/$groupId";
import DeleteGroup from "~/routes/groups/$groupId.delete";
import EditGroup from "~/routes/groups/$groupId.edit";
import AddFirefighterToGroup from "~/routes/groups/$groupId.members.add";
import DeleteGroupMembership from "~/routes/groups/$groupId.members.delete";
import GroupsIndex from "~/routes/groups/groups";
import CreateGroup from "~/routes/groups/new";
import Login from "~/routes/login/login";
import JoinOrganization from "~/routes/onboarding/join";
import OnboardingIndex from "~/routes/onboarding/onboarding";
import EditOrganizationUser from "~/routes/organization/edit.$userId";
import InviteToOrganization from "~/routes/organization/invite";
import OrganisationIndex from "~/routes/organization/organization";
import ScbaSessionDetail from "~/routes/scbasessions/$scbaSessionId";
import DeleteScbaSessionParticipation from "~/routes/scbasessions/$scbaSessionId.$firefighterId.delete";
import AddFirefighterToScbaSession from "~/routes/scbasessions/$scbaSessionId.addfirefighter";
import DeleteScbaSession from "~/routes/scbasessions/$scbaSessionId.delete";
import EditScbaSession from "~/routes/scbasessions/$scbaSessionId.edit";
import CreateScbaSession from "~/routes/scbasessions/new";
import ScbaSessionsIndex from "~/routes/scbasessions/scbasessions";
import SignUp from "~/routes/signup/signup";

import ReactDOM from "react-dom/client";
import { AuthProvider } from "~/context/AuthProvider";
import "./index.css";

const ProviderLayout = () => (
  <AuthProvider>
    <GraphQLProvider>
      <ForceOnboarding>
        <Outlet />
      </ForceOnboarding>
    </GraphQLProvider>
  </AuthProvider>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ProviderLayout />}>
      <Route index element={<Index />} />
      <Route path="login" element={<Login />} />
      <Route path="signup" element={<SignUp />} />
      <Route
        path="account"
        element={
          <Shell>
            <AccountIndex />
          </Shell>
        }
      >
        <Route path="delete" element={<DeleteAccount />} />
      </Route>
      <Route
        path="organization"
        element={
          <Shell>
            <OrganisationIndex />
          </Shell>
        }
      >
        <Route path="invite" element={<InviteToOrganization />} />
        <Route path="user/:userId" element={<EditOrganizationUser />} />
      </Route>
      <Route
        path="onboarding"
        element={
          <Shell disabled>
            <OnboardingIndex />
          </Shell>
        }
      />
      <Route
        path="onboarding/join"
        element={
          <Shell disabled>
            <JoinOrganization />
          </Shell>
        }
      />
      <Route
        path="firefighters"
        element={
          <Shell>
            <FirefightersIndex />
          </Shell>
        }
      >
        <Route path="new" element={<NewFirefighter />} />
      </Route>
      <Route
        path="firefighters/:firefighterId"
        element={
          <Shell>
            <FirefighterDetail />
          </Shell>
        }
      >
        <Route path="edit" element={<EditFirefighter />} />
        <Route path="delete" element={<DeleteFirefighter />} />
        <Route
          path="firestations/add"
          element={<AddFirefighterToFirestation />}
        />
        <Route
          path="firestations/:firestationId/delete"
          element={<DeleteFirestationMembership />}
        />
        <Route
          path="scbasessions/:scbaSessionId/edit"
          element={<EditScbaSessionParticipation />}
        />
        <Route
          path="scbasessions/:scbaSessionId/delete"
          element={<DeleteScbaSessionParticipations />}
        />
        <Route path="examinations/add" element={<CreateExamination />} />
        <Route
          path="examinations/:examinationId/edit"
          element={<EditExamination />}
        />
        <Route
          path="examinations/:examinationId/delete"
          element={<DeleteExamination />}
        />
        <Route path="code" element={<ShowFirefighterCode />} />
      </Route>
      <Route
        path="firestations"
        element={
          <Shell>
            <FirestationsIndex />
          </Shell>
        }
      >
        <Route path="new" element={<CreateFirestation />} />
      </Route>
      <Route
        path="firestations/:firestationId"
        element={
          <Shell>
            <FirestationDetail />
          </Shell>
        }
      >
        <Route path="delete" element={<DeleteFirestation />} />
        <Route path="edit" element={<EditFirestation />} />
      </Route>
      <Route
        path="groups"
        element={
          <Shell>
            <GroupsIndex />
          </Shell>
        }
      >
        <Route path="new" element={<CreateGroup />} />
      </Route>
      <Route
        path="groups/:groupId"
        element={
          <Shell>
            <GroupDetail />
          </Shell>
        }
      >
        <Route path="edit" element={<EditGroup />} />
        <Route path="delete" element={<DeleteGroup />} />
        <Route path="members/add" element={<AddFirefighterToGroup />} />
        <Route
          path="members/:firefighterId/delete"
          element={<DeleteGroupMembership />}
        />
      </Route>
      <Route
        path="scbasessions"
        element={
          <Shell>
            <ScbaSessionsIndex />
          </Shell>
        }
      >
        <Route path="new" element={<CreateScbaSession />} />
      </Route>
      <Route
        path="scbasessions/:scbaSessionId"
        element={
          <Shell>
            <ScbaSessionDetail />
          </Shell>
        }
      >
        <Route path="edit" element={<EditScbaSession />} />
        <Route path="delete" element={<DeleteScbaSession />} />
        <Route
          path="addfirefighter"
          element={<AddFirefighterToScbaSession />}
        />
        <Route
          path=":firefighterId/delete"
          element={<DeleteScbaSessionParticipation />}
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
    <Toaster position="top-center" />
  </React.StrictMode>
);
