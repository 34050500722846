import { graphql } from "~/gql";

export const OrganizationDetailQueryDocument = graphql(`
  query OrganizationDetail {
    organization {
      name
    }
    users {
      id
      name
      roles
      email
    }
  }
`);
