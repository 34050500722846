import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import SlideOver from "~/components/SlideOver";
import { CreateInvitationMutationDocument } from "~/mutations/CreateInvitation.mutation";
import { Role } from "~/types/Role.enum";
import { extractCode } from "~/util/error";
import { RoleUtil } from "~/util/roles";

type Inputs = {
  invitedUserEmail: string;
  initialRole: Role;
};

export default function InviteToOrganization() {
  const [shouldClose, setShouldClose] = useState(false);

  const [createInvitation, { error }] = useMutation(
    CreateInvitationMutationDocument,
    {
      refetchQueries: []
    }
  );

  const { register, handleSubmit } = useForm<Inputs>();

  useEffect(() => {
    if (error) {
      const code = extractCode(error);
      switch (code) {
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, eine Einladung zu versenden."
          );
          break;
        case "USER_ALREADY_PART_OF_ORGANIZATION":
          toast.error("Der Benutzer ist bereits Teil der Organisation.");
          break;
        default:
          toast.error("Unerwarteter Fehler beim Versenden der Einladung.");
          break;
      }
    }
  }, [error]);

  const submitHandler: SubmitHandler<Inputs> = (data) => {
    createInvitation({
      variables: { input: data }
    })
      .then(() => toast.success("Einladung erfolgreich gesendet"))
      .then(() => setShouldClose(true))
      .catch(() => {});
  };

  return (
    <SlideOver title="Benutzer einladen" shouldClose={shouldClose}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="invitedUserEmail"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  E-Mail Adresse des Benutzers{" "}
                  <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    id="invitedUserEmail"
                    autoComplete="off"
                    required
                    placeholder='z.B. "m.muster@ff-stadt.de"'
                    {...register("invitedUserEmail")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="invitedUserEmail"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Rolle <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <select
                    id="initialRole"
                    required
                    className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    {...register("initialRole")}
                  >
                    {Object.entries(Role).map(([key, value]) => (
                      <option key={key} value={key}>
                        {RoleUtil.toDisplayString(value)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Einladung senden
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
