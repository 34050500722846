import { format, isValid } from "date-fns";

export const dateToHtmlDateInput = (d: Date) =>
  isValid(d) ? format(d, "yyyy-MM-dd") : "";

export const dateFromHtmlDateInput = (s: string) => new Date(s + "T00:00");

export const dateToHtmlDateTimeInput = (d: Date) =>
  isValid(d) ? format(d, "yyyy-MM-dd'T'HH:mm") : "";

export const dateFromHtmlDateTimeInput = (s: string) => new Date(s);

export function germanDate(date: Date | string) {
  const definitelyDate = new Date(date);
  return format(definitelyDate, "dd.MM.yyyy");
}

export function germanTime(date: Date | string) {
  const definitelyDate = new Date(date);
  return format(definitelyDate, "HH:mm");
}

export function germanDateWithTime(date: Date | string) {
  const definitelyDate = new Date(date);
  return format(definitelyDate, "dd.MM.yyyy HH:mm");
}
