export default function Spinner() {
  return (
    <svg
      aria-hidden="true"
      className="mr-2 h-8 w-8 animate-spin fill-primary-600 text-gray-200 dark:text-gray-600"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className="opacity-25"
        cx="50"
        cy="50"
        r="45"
        stroke="#585F6E"
        strokeWidth="10"
        fill="none"
      ></circle>
      <path
        className="opacity-75"
        fill="#0369A1"
        d="M95 50A45 45 0 0 1 50 95V50z"
      ></path>
    </svg>
  );
}
