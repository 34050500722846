import { graphql } from "~/gql";

export const ScbaSessionParticipationDetailQueryDocument = graphql(`
  query ScbaSessionParticipationDetail(
    $scbaSessionId: ID!
    $firefighterId: ID!
  ) {
    scbaSessionParticipation(
      scbaSessionId: $scbaSessionId
      firefighterId: $firefighterId
    ) {
      initialPressure
      endPressure
    }
  }
`);
