import { graphql } from "~/gql";

export const AddFirefighterToScbaSessionQueryDocument = graphql(`
  query AddFirefighterToScbaSession($scbaSessionId: ID!) {
    scbaSession(id: $scbaSessionId) {
      participations {
        firefighter {
          id
        }
      }
    }
    firefighters {
      id
      firstName
      lastName
      firestations {
        name
      }
    }
  }
`);
