import { useQuery } from "@apollo/client";
import { Link, Outlet } from "react-router-dom";
import DebouncedLoadingTable from "~/components/DebouncedLoadingTable";
import ErrorDisplay from "~/components/ErrorDisplay";
import { ListAllGroupsQueryDocument } from "~/queries/ListAllGroups.query";

export default function GroupsIndex() {
  const { error, loading, data } = useQuery(ListAllGroupsQueryDocument);

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Gruppen{" "}
              <span className="ml-1 font-normal text-gray-400">
                {data?.groups.length ?? ""}
              </span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">{/* Subtitle */}</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Link to="/groups/new">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto"
              >
                Neue Gruppe anlegen
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <DebouncedLoadingTable loading={loading}>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            # Einsatzkräfte
                          </th>
                          <th
                            scope="col"
                            className="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Details</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {[...(data?.groups ?? [])]
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((group) => {
                            return (
                              <tr key={group.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {group.name}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {group.numberOfMembers}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link
                                    to={group.id}
                                    className="text-sky-600 hover:text-sky-900"
                                  >
                                    Details
                                    <span className="sr-only">
                                      , {group.name}
                                    </span>
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </DebouncedLoadingTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
