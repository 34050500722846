import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import SlideOver from "~/components/SlideOver";
import {
  FirestationDetailDocument,
  FirestationDetailSimpleDocument,
  ListAllFirestationsDocument
} from "~/gql/graphql";
import { UpdateFirestationMutationDocument } from "~/mutations/UpdateFirestation.mutation";
import { FirestationDetailSimpleQueryDocument } from "~/queries/FirestationDetailSimple.query";
import { extractCode } from "~/util/error";

type Inputs = {
  name: string;
};

export default function EditFirestation() {
  const [shouldClose, setShouldClose] = useState(false);

  const params = useParams();
  const firestationId = params.firestationId as string;

  const { error: queryError, data: queryData } = useQuery(
    FirestationDetailSimpleQueryDocument,
    {
      variables: { firestationId }
    }
  );

  useEffect(() => {
    if (queryError) {
      const code = extractCode(queryError);
      switch (code) {
        case "NOT_FOUND":
          toast.error("Die Feuerwehr wurde nicht gefunden.");
          break;
        case "FORBIDDEN":
          toast.error("Sie sind nicht berechtigt, diese Feuerwehr abzurufen.");
          break;
        default:
          toast.error("Unewarteter Fehler beim Abrufen der Feuerwehr");
          break;
      }
      setShouldClose(true);
    }
  }, [queryError]);

  const [updateFirestation, { error: mutationError }] = useMutation(
    UpdateFirestationMutationDocument,
    {
      refetchQueries: [
        ListAllFirestationsDocument,
        {
          query: FirestationDetailDocument,
          variables: { firestationId }
        },
        {
          query: FirestationDetailSimpleDocument,
          variables: { firestationId }
        }
      ]
    }
  );

  useEffect(() => {
    if (mutationError) {
      const code = extractCode(mutationError);
      switch (code) {
        case "NOT_FOUND":
          toast.error("Die Feuerwehr wurde nicht gefunden.");
          break;
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, diese Feuerwehr zu bearbeiten."
          );
          break;
        default:
          toast.error("Unerwarteter Fehler beim Bearbeiten der Feuerwehr.");
          break;
      }
    }
  }, [mutationError]);

  const { register, handleSubmit } = useForm<Inputs>();

  const submitHandler: SubmitHandler<Inputs> = (data) => {
    const { name } = data;
    updateFirestation({
      variables: { input: { id: firestationId, name } }
    }).then(() => setShouldClose(true));
  };

  return (
    <SlideOver title="Feuerwehr bearbeiten" shouldClose={shouldClose}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name (bitte ohne "Feuerwehr"){" "}
                  <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="name"
                    autoComplete="off"
                    required
                    placeholder='z.B. "Musterhausen"'
                    defaultValue={queryData?.firestation?.name ?? ""}
                    {...register("name")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Änderungen speichern
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
