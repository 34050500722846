import { useQuery } from "@apollo/client";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Link, Outlet, useParams } from "react-router-dom";
import DebouncedLoadingTable from "~/components/DebouncedLoadingTable";
import ErrorDisplay from "~/components/ErrorDisplay";
import { ExaminationType } from "~/gql/graphql";
import { FirestationDetailQueryDocument } from "~/queries/FirestationDetail.query";
import { germanDate } from "~/util/dates";

export default function FirestationDetail() {
  const params = useParams();
  const firestationId = params.firestationId as string;

  const { error, loading, data } = useQuery(FirestationDetailQueryDocument, {
    variables: { firestationId, examinationType: ExaminationType.G26_3 }
  });

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <ul role="list">
          {/* Info */}
          <div className="py-4">
            <div className="bg-white p-4 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div>
                <h1 className="text-xl font-semibold text-gray-900">
                  Feuerwehr {data?.firestation?.name}
                </h1>
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Einsatzkräfte
                </h1>
              </div>
            </div>
            <div className="mt-4">
              <div className="flex flex-col">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <DebouncedLoadingTable loading={loading}>
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Feuerwehren
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Letzte Belastungsübung
                              </th>
                              <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                G26
                              </th>
                              <th
                                scope="col"
                                className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                              >
                                <span className="sr-only">Edit</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {(data?.firestation?.firefighters ?? []).map(
                              (firefighter, i) => {
                                return (
                                  <tr key={`firefighter_${i}`}>
                                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                      {firefighter.firstName}{" "}
                                      {firefighter.lastName}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {firefighter.firestations
                                        .map((f) => f.name)
                                        .join(", ")}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {firefighter.lastScbaSessionParticipation
                                        ? germanDate(
                                            firefighter
                                              .lastScbaSessionParticipation
                                              .scbaSession.date
                                          )
                                        : "—"}
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {firefighter.hasValidExamination ? (
                                        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                          Gültig
                                        </span>
                                      ) : (
                                        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                          Abgelaufen
                                        </span>
                                      )}
                                    </td>
                                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                      <Link
                                        to={`/firefighters/${firefighter.id}`}
                                        className="text-sky-600 hover:text-sky-900"
                                      >
                                        Details
                                        <span className="sr-only">
                                          , {firefighter.firstName}{" "}
                                          {firefighter.lastName}
                                        </span>
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              }
                            )}
                          </tbody>
                        </table>
                      </DebouncedLoadingTable>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row gap-4 py-4">
            <Link to="edit" preventScrollReset>
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                <PencilSquareIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                Feuerwehr bearbeiten
              </button>
            </Link>
            <Link to="delete" preventScrollReset>
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Feuerwehr löschen
              </button>
            </Link>
          </div>
        </ul>
      </div>
      <Outlet />
    </>
  );
}
