import { Link, Outlet } from "react-router-dom";

export default function AccountIndex() {
  const user = {
    given_name: "Max",
    family_name: "Mustermann",
    email: "dummy email"
  };

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Persönliche Daten
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Diese Daten sind für Nutzer innerhalb Ihrer Organisation
                sichtbar.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Name
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    {user?.given_name && user?.family_name ? (
                      <div className="text-gray-900">
                        {user.given_name} {user.family_name}
                      </div>
                    ) : (
                      <div className="text-gray-400">Nicht festgelegt</div>
                    )}
                    <button
                      type="button"
                      className="font-semibold text-primary-600 hover:text-primary-500"
                    >
                      Bearbeiten
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    E-Mail Adresse
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    {user?.email ? (
                      <div className="text-gray-900">{user.email}</div>
                    ) : (
                      <div className="text-gray-400">Nicht festgelegt</div>
                    )}
                    <button
                      type="button"
                      className="font-semibold text-primary-600 hover:text-primary-500"
                    >
                      Bearbeiten
                    </button>
                  </dd>
                </div>
              </dl>
            </div>

            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Kontoverwaltung
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500"></p>
              <div className="mt-4 rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="ml-3 flex-1 md:flex md:items-center md:justify-between">
                    <div>
                      <p className="text-sm font-medium text-red-700">
                        Konto löschen
                      </p>
                      <div className="mt-2 text-sm text-red-700">
                        <ul role="list" className="list-disc space-y-1 pl-5">
                          <li>
                            Sie velieren den Zugriff auf die in acensis
                            gespeicherten Daten
                          </li>
                          <li>
                            Es muss weiterhin mindestens einen Administrator in
                            Ihrer Organisation geben
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p className="mt-3 text-sm md:ml-6 md:mt-0">
                      <Link to="delete" preventScrollReset>
                        <button
                          type="button"
                          className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm text-white shadow-sm transition-all hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                        >
                          Konto löschen
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
