import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "~/hooks/useAuth";

type Inputs = {
  email: string;
  password: string;
};

export default function Login() {
  const { register, handleSubmit } = useForm<Inputs>();
  const { login } = useAuth();
  const navigate = useNavigate();

  const submitHandler: SubmitHandler<Inputs> = async ({ email, password }) => {
    const loginResult = await login(email, password);
    if (loginResult.ok) {
      navigate("/");
      return;
    } else {
      switch (loginResult.val) {
        case "invalid_credentials":
          toast.error(
            "Die eingegebene E-Mail Adresse oder das Passwort ist falsch"
          );
          break;
        case "unknown_error":
          toast.error(
            "Unbekannter Fehler bei der Anmeldung. Bitte versuchen Sie es später erneut."
          );
          break;
      }
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              alt="acensis"
              src="/acensis.logo.v1.black.svg"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Mit Ihrem Konto anmelden
            </h2>
          </div>
          <form className="space-y-4" onSubmit={handleSubmit(submitHandler)}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="email-address" className="sr-only">
                  E-Mail Adresse
                </label>
                <input
                  {...register("email", { required: true })}
                  id="email-address"
                  type="email"
                  placeholder="E-Mail Adresse"
                  autoComplete="email"
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Passwort
                </label>
                <input
                  {...register("password", { required: true })}
                  id="password"
                  type="password"
                  placeholder="Passwort"
                  autoComplete="current-password"
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm leading-6">
                <a
                  href="#"
                  className="font-semibold text-primary-600 transition-colors hover:text-primary-500"
                >
                  Passwort vergessen?
                </a>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white transition-colors hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Anmelden
              </button>
            </div>
          </form>

          <p className="text-center text-sm leading-6 text-gray-500">
            Neu bei acensis?{" "}
            <Link
              to="/signup"
              className="font-semibold text-primary-600 transition-colors hover:text-primary-500"
            >
              Jetzt 3 Monate kostenlos testen
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
