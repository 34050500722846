import { useMutation } from "@apollo/client";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import SlideOver from "~/components/SlideOver";
import { ListAllScbaSessionsDocument } from "~/gql/graphql";
import { CreateScbaSessionMutationDocument } from "~/mutations/CreateScbaSession.mutation";
import { dateToHtmlDateTimeInput } from "~/util/dates";

type Inputs = {
  date: Date;
  comment?: string;
};

export default function CreateScbaSession() {
  const [shouldClose, setShouldClose] = useState(false);

  const { register, handleSubmit } = useForm<Inputs>();

  const [createScbaSession] = useMutation(CreateScbaSessionMutationDocument, {
    refetchQueries: [ListAllScbaSessionsDocument]
  });

  const submitHandler: SubmitHandler<Inputs> = (formData) => {
    createScbaSession({
      variables: { input: formData }
    })
      .then(() => setShouldClose(true))
      .catch(() => toast.error("Fehler beim Speichern der Belastungsübung"));
  };

  return (
    <SlideOver
      title="Neue Belastungsübung hinzufügen"
      shouldClose={shouldClose}
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700"
                >
                  Datum & Uhrzeit{" "}
                  <span className="font-light text-red-600">*</span>
                </label>
                <input
                  type="datetime-local"
                  id="date"
                  autoComplete="off"
                  required
                  defaultValue={dateToHtmlDateTimeInput(new Date())}
                  {...register("date")}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>

              <div>
                <label
                  htmlFor="comment"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Kommentar
                </label>
                <div className="mt-2">
                  <textarea
                    id="comment"
                    autoComplete="off"
                    placeholder='z.B. "Maskenbrille beschädigt"'
                    {...register("comment")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Belastungsübung hinzufügen
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
