import { isPast } from "date-fns";
import { decodeJwt } from "jose";
import { Err, Ok, Result } from "ts-results";

export async function getTokens(
  email: string,
  password: string
): Promise<
  Result<{ accessToken: string }, "invalid_credentials" | "unknown_error">
> {
  const response = await fetch(
    `${import.meta.env.VITE_HTTP_ENDPOINT}/authentication/sign-in`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ email, password }),
      credentials: "include"
    }
  );
  if (!response.ok) {
    switch (response.status) {
      case 401:
        return new Err("invalid_credentials");
      default:
        return new Err("unknown_error");
    }
  }
  const responseJson = await response.json();
  const accessToken = responseJson.accessToken;
  if (!accessToken) return new Err("unknown_error");
  return new Ok({ accessToken });
}

export async function createAccount(
  name: string,
  email: string,
  password: string
): Promise<Result<void, "account_creation_failed">> {
  const response = await fetch(
    `${import.meta.env.VITE_HTTP_ENDPOINT}/authentication/sign-up`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ name, email, password })
    }
  );
  if (!response.ok) {
    return new Err("account_creation_failed");
  } else {
    return Ok.EMPTY;
  }
}

export function isTokenExpired(token: string): boolean {
  const { exp } = decodeJwt(token);
  if (!exp) return true;
  const expirationDate = new Date(exp * 1000); // Convert to milliseconds
  return isPast(expirationDate);
}

export async function refreshAccessToken(): Promise<string | null> {
  const response = await fetch(
    `${import.meta.env.VITE_HTTP_ENDPOINT}/tokens/refresh`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      credentials: "include"
    }
  );
  if (!response.ok) return null;
  const responseJson = await response.json();
  if (!responseJson.accessToken) return null;
  return responseJson.accessToken;
}

export async function removeRefreshToken() {
  await fetch(`${import.meta.env.VITE_HTTP_ENDPOINT}/tokens/remove`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    credentials: "include"
  });
}
