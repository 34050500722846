import { graphql } from "~/gql";

export const UpdateScbaSessionMutationDocument = graphql(`
  mutation UpdateScbaSession($input: ScbaSessionUpdateInput!) {
    scbaSessionUpdate(input: $input) {
      scbaSession {
        id
      }
    }
  }
`);
