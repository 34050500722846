import { graphql } from "~/gql";

export const UpdateFirestationMutationDocument = graphql(`
  mutation UpdateFirestation($input: FirestationUpdateInput!) {
    firestationUpdate(input: $input) {
      firestation {
        id
      }
    }
  }
`);
