import { graphql } from "~/gql";

export const CreateExaminationMutationDocument = graphql(`
  mutation CreateExamination($input: ExaminationCreateInput!) {
    examinationCreate(input: $input) {
      examination {
        id
      }
    }
  }
`);
