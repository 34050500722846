import { graphql } from "~/gql";

export const CreateOrganizationMutationDocument = graphql(`
  mutation CreateOrganization($input: OrganizationCreateInput!) {
    organizationCreate(input: $input) {
      organization {
        id
      }
    }
  }
`);
