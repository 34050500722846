import GenericError from "./GenericError";

export default function Unauthorized() {
  return (
    <GenericError
      title="Zugriff verweigert"
      code=""
      message="Ihnen fehlt die Berechtigung, um auf dieses Objekt zuzugreifen. Wenden Sie sich bei Fragen bitte an den Administrator Ihrer Feuerwehr."
    />
  );
}
