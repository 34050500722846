import { useMutation, useQuery } from "@apollo/client";
import { addYears } from "date-fns";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import SlideOver from "~/components/SlideOver";
import {
  ExaminationDetailDocument,
  ExaminationType,
  FirefighterDetailDocument,
  ListAllFirefightersDocument
} from "~/gql/graphql";
import { UpdateExaminationMutationDocument } from "~/mutations/UpdateExamination.mutation";
import { ExaminationDetailQueryDocument } from "~/queries/ExaminationDetail.query";
import { dateFromHtmlDateInput, dateToHtmlDateInput } from "~/util/dates";
import { extractCode } from "~/util/error";

type Inputs = {
  examinedAt: Date;
  validUntil: Date;
  type: ExaminationType;
};

export default function EditExamination() {
  const params = useParams();
  const firefighterId = params.firefighterId as string;
  const examinationId = params.examinationId as string;

  const [shouldClose, setShouldClose] = useState(false);
  const today = new Date();

  const addYearsToValidUntil = (years: number) => {
    const examinedAtAsDate = dateFromHtmlDateInput(examinedAt);
    const validUntilAsDate = addYears(examinedAtAsDate, years);
    setValidUntil(dateToHtmlDateInput(validUntilAsDate));
  };

  type AutoDateMode = "OFF" | "1" | "2" | "3";

  const [examinedAt, setExaminedAt] = useState(dateToHtmlDateInput(today));
  const [validUntil, setValidUntil] = useState<string>("");
  const [autoDateMode, setAutoDateMode] = useState<AutoDateMode>("OFF");

  useEffect(() => {
    switch (autoDateMode) {
      case "1":
        addYearsToValidUntil(1);
        break;
      case "2":
        addYearsToValidUntil(2);
        break;
      case "3":
        addYearsToValidUntil(3);
        break;
      case "OFF":
      default:
        break;
    }
  }, [examinedAt, autoDateMode]);

  const { error: queryError, data: queryData } = useQuery(
    ExaminationDetailQueryDocument,
    {
      variables: { examinationId }
    }
  );

  useEffect(() => {
    if (queryError) {
      const code = extractCode(queryError);
      switch (code) {
        case "NOT_FOUND":
          toast.error("Die zu bearbeitende Untersuchung wurde nicht gefunden.");
          break;
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, diese Untersuchung abzurufen."
          );
          break;
        default:
          toast.error("Unerwarteter Fehler beim Laden der Untersuchung.");
          break;
      }
      setShouldClose(true);
    }
  }, [queryError]);

  useEffect(() => {
    if (queryData && queryData.examination) {
      setExaminedAt(
        dateToHtmlDateInput(new Date(queryData.examination.examinedAt))
      );
      setValidUntil(
        dateToHtmlDateInput(new Date(queryData.examination.validUntil))
      );
    }
  }, [queryData]);

  const [
    createExamination,
    { error: mutationError, loading: mutationLoading }
  ] = useMutation(UpdateExaminationMutationDocument, {
    refetchQueries: [
      { query: FirefighterDetailDocument, variables: { firefighterId } },
      { query: ExaminationDetailDocument, variables: { examinationId } },
      ListAllFirefightersDocument
    ]
  });

  useEffect(() => {
    if (mutationError) {
      const code = extractCode(mutationError);
      switch (code) {
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, diese Untersuchung zu aktualisieren."
          );
          break;
        case "NOT_FOUND":
          toast.error("Die Untersuchung wurde nicht gefunden.");
          break;
        default:
          toast.error(
            "Unerwarteter Fehler beim Aktualisieren der Untersuchung."
          );
          break;
      }
    }
  }, [mutationError]);

  const { handleSubmit, register } = useForm<Inputs>();
  const submitHandler: SubmitHandler<Inputs> = (formData) => {
    const { examinedAt: __, validUntil: _, ...sanitizedFormData } = formData;

    createExamination({
      variables: {
        input: {
          id: examinationId,
          examinedAt: dateFromHtmlDateInput(examinedAt),
          validUntil: dateFromHtmlDateInput(validUntil),
          ...sanitizedFormData
        }
      }
    }).then(() => setShouldClose(true));
  };

  return (
    <SlideOver title="Untersuchung bearbeiten" shouldClose={shouldClose}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="examinedAt"
                  className="block text-sm font-medium text-gray-700"
                >
                  Datum der Untersuchung{" "}
                  <span className="font-light text-red-600">*</span>
                </label>
                <input
                  type="date"
                  id="examinedAt"
                  autoComplete="off"
                  required
                  defaultValue={dateToHtmlDateInput(
                    new Date(queryData?.examination?.examinedAt)
                  )}
                  onInput={(e) => setExaminedAt(e.currentTarget.value)}
                  {...register("examinedAt", { valueAsDate: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="validUntil"
                  className="block text-sm font-medium text-gray-700"
                >
                  Gültig bis <span className="font-light text-red-600">*</span>
                </label>
                <input
                  type="date"
                  id="validUntil"
                  autoComplete="off"
                  required
                  value={validUntil}
                  onInput={(e) => {
                    setAutoDateMode("OFF");
                    setValidUntil(e.currentTarget.value);
                  }}
                  {...register("validUntil", { valueAsDate: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />

                <span className="isolate mt-2 inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    onClick={() => setAutoDateMode("1")}
                    className="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    1 Jahr
                  </button>
                  <button
                    type="button"
                    onClick={() => setAutoDateMode("2")}
                    className="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    2 Jahre
                  </button>
                  <button
                    type="button"
                    onClick={() => setAutoDateMode("3")}
                    className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
                  >
                    3 Jahre
                  </button>
                </span>
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Art der Untersuchung{" "}
                  <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <select
                    id="type"
                    autoComplete="off"
                    defaultValue={queryData?.examination?.type ?? "G26_3"}
                    required
                    {...register("type")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="G26_3">G26.3</option>
                    <option value="G26_2">G26.2</option>
                    <option value="G26_1">G26.1</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            disabled={mutationLoading}
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Änderungen speichern
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
