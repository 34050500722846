import { useEffect } from "react";

export function useBrowserBlocker(when: boolean) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = null;
    }

    // Cleanup once navigated away
    return () => {
      window.onbeforeunload = null;
    };
  }, [when]);
}
