import { graphql } from "~/gql";

export const UpdateExaminationMutationDocument = graphql(`
  mutation UpdateExamination($input: ExaminationUpdateInput!) {
    examinationUpdate(input: $input) {
      examination {
        id
      }
    }
  }
`);
