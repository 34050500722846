import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "~/hooks/useAuth";

type Inputs = {
  name: string;
  email: string;
  password: string;
};

export default function SignUp() {
  const { register, handleSubmit } = useForm<Inputs>();
  const { createAccount } = useAuth();
  const navigate = useNavigate();

  const submitHandler: SubmitHandler<Inputs> = async ({
    name,
    email,
    password
  }) => {
    const accountCreationResult = await createAccount(name, email, password);
    if (accountCreationResult.ok) {
      toast.success(
        "Konto erfolgreich erstellt, Sie können sich nun anmelden.",
        { duration: 10_000 }
      );
      navigate("/login");
      return;
    } else {
      toast.error(
        "Fehler bei der Kontoerstellung, bitte versuchen Sie es erneut."
      );
    }
  };

  return (
    <>
      <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-10">
          <div>
            <img
              alt="acensis"
              src="/acensis.logo.v1.black.svg"
              className="mx-auto h-10 w-auto"
            />
            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              Neues Konto erstellen
            </h2>
          </div>
          <form className="space-y-4" onSubmit={handleSubmit(submitHandler)}>
            <div className="relative -space-y-px rounded-md shadow-sm">
              <div className="pointer-events-none absolute inset-0 z-10 rounded-md ring-1 ring-inset ring-gray-300" />
              <div>
                <label htmlFor="name" className="sr-only">
                  Name
                </label>
                <input
                  {...register("name", { required: true })}
                  id="name"
                  type="text"
                  placeholder="Name"
                  autoComplete="name"
                  className="relative block w-full rounded-t-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div>
                <label htmlFor="email-address" className="sr-only">
                  E-Mail Adresse
                </label>
                <input
                  {...register("email", { required: true })}
                  id="email-address"
                  type="email"
                  placeholder="E-Mail Adresse"
                  autoComplete="email"
                  className="relative block w-full border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Passwort
                </label>
                <input
                  {...register("password", { required: true })}
                  id="password"
                  type="password"
                  placeholder="Passwort"
                  autoComplete="current-password"
                  className="relative block w-full rounded-b-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-100 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-primary-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white transition-colors hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
              >
                Jetzt Konto erstellen
              </button>
            </div>
          </form>

          <p className="text-center text-sm leading-6 text-gray-500">
            Sie haben schon ein Konto?{" "}
            <Link
              className="font-semibold text-primary-600 transition-colors hover:text-primary-500"
              to="/login"
            >
              Jetzt anmelden
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
