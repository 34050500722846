import { graphql } from "~/gql";

export const CreateGroupMembershipMutationDocument = graphql(`
  mutation CreateGroupMembership($input: GroupMembershipCreateInput!) {
    groupMembershipCreate(input: $input) {
      groupMembership {
        group {
          id
        }
      }
    }
  }
`);
