import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "~/hooks/useAuth";
import { CreateOrganizationMutationDocument } from "~/mutations/CreateOrganization.mutation";
import { extractCode } from "~/util/error";

type Inputs = {
  name: string;
};

export default function OnboardingIndex() {
  const [createOrganization, { loading, error, data }] = useMutation(
    CreateOrganizationMutationDocument
  );
  const { register, handleSubmit } = useForm<Inputs>();
  const { claims, refreshClaims } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (claims && claims.organizationId) {
      navigate("/");
    }
  }, [claims]);

  useEffect(() => {
    if (error) {
      const code = extractCode(error);
      switch (code) {
        case "ALREADY_PART_OF_AN_ORGANIZATION":
          toast.error("Sie sind bereits Teil einer Organisation");
          break;
        default:
          toast.error("Unerwarteter Fehler beim Erstellen der Organisation");
          break;
      }
    } else if (data) {
      refreshClaims().catch(() =>
        toast.error(
          "Fehler beim Aktualisieren der Berechtigungen. Bitte loggen Sie sich erneut ein."
        )
      );
    }
  }, [error, data]);

  const submitHandler: SubmitHandler<Inputs> = (formData) =>
    createOrganization({
      variables: { input: formData }
    });

  return (
    <>
      <div className="flex h-full flex-col items-center justify-center">
        <div>
          <div className="mt-4 overflow-hidden bg-white sm:rounded-lg sm:shadow">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Willkommen bei acensis!
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Um zu starten, müssen Sie zuerst eine Organisation erstellen.
                Sie sind dann Administrator und können weitere Benutzer
                hinzufügen.
              </p>
            </div>
            <div className="p-8">
              <form onSubmit={handleSubmit(submitHandler)}>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Name der Organisation
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 md:w-1/2"
                    placeholder='z.B. "Landkreis Musterlingen"'
                    aria-autocomplete="none"
                    required
                    {...register("name")}
                  />
                </div>
                <p
                  className="mt-2 text-sm text-gray-500"
                  id="email-description"
                >
                  Dieser Name kann später geändert werden.
                </p>
                <button
                  disabled={loading}
                  type="submit"
                  className="mt-4 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition-colors hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  Organisation erstellen
                </button>
              </form>
            </div>
            <div className="m-6 bg-primary-100 sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Sie möchten einer bestehenden Organisation beitreten?
                </h3>
                <div className="mt-2 text-sm text-gray-500">
                  <p>
                    Bitte Sie Ihren Administrator Sie einzuladen, daraufhin
                    erhalten Sie eine Einladung per E-Mail.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
