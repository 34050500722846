import { graphql } from "~/gql";

export const CreateFirestationMembershipMutationDocument = graphql(`
  mutation CreateFirestationMembership(
    $input: FirestationMembershipCreateInput!
  ) {
    firestationMembershipCreate(input: $input) {
      firestationMembership {
        firefighter {
          id
        }
      }
    }
  }
`);
