import { graphql } from "~/gql";

export const AddFirefighterToGroupQueryDocument = graphql(`
  query AddFirefighterToGroup($groupId: ID!) {
    group(id: $groupId) {
      members {
        id
      }
    }
    firefighters {
      id
      firstName
      lastName
      firestations {
        name
      }
    }
  }
`);
