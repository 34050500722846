import { useMutation, useQuery } from "@apollo/client";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ErrorDisplay from "~/components/ErrorDisplay";
import NotFound from "~/components/NotFound";
import Spinner from "~/components/Spinner";
import { useAuth } from "~/hooks/useAuth";
import { AcceptInvitationMutationDocument } from "~/mutations/AcceptInvitation.mutation";
import { InvitationDetailQueryDocument } from "~/queries/InvitationDetail.query";

export default function JoinOrganization() {
  const [acceptInvitation, { loading: mutationLoading, data: mutationData }] =
    useMutation(AcceptInvitationMutationDocument);
  const { claims, refreshClaims } = useAuth();

  const [search] = useSearchParams();
  const ticket = search.get("ticket");

  const {
    loading: queryLoading,
    data: queryData,
    error
  } = useQuery(InvitationDetailQueryDocument, {
    variables: { invitationId: ticket ?? "" }
  });

  const navigate = useNavigate();
  useEffect(() => {
    if (claims && claims.organizationId) {
      navigate("/");
    } else if (!ticket) {
      navigate("/onboarding");
    }
  }, [claims, ticket]);

  useEffect(() => {
    if (mutationData?.invitationAccept.acceptedInvitationId === ticket) {
      refreshClaims()
        .then(() => navigate("/"))
        .catch(() =>
          toast.error(
            "Fehler beim Aktualisieren der Berechtigungen. Bitte loggen Sie sich erneut ein."
          )
        );
    }
  }, [mutationData]);

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (queryLoading) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  if (!queryData?.invitation) {
    return <NotFound />;
  }

  const { invitation } = queryData;

  return (
    <>
      <div className="flex h-full flex-col items-center justify-center">
        <div>
          <div className="mt-4 overflow-hidden bg-white sm:rounded-lg sm:shadow">
            <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
              <h3 className="text-base font-semibold leading-6 text-gray-900">
                Willkommen bei acensis!
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Sie wurden eingeladen, der Organisation{" "}
                <span className="font-bold">
                  {invitation.organization.name}
                </span>{" "}
                beizutreten.
              </p>
            </div>
            <div className="px-8">
              <button
                disabled={mutationLoading}
                type="submit"
                className="mt-4 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm transition-colors hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                onClick={() =>
                  acceptInvitation({
                    variables: { input: { invitationId: ticket ?? "" } }
                  })
                }
              >
                <span className="underline">
                  {invitation.organization.name}
                </span>{" "}
                jetzt beitreten
              </button>
            </div>
            <div className="m-6 bg-primary-100 sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-base font-semibold leading-6 text-gray-900">
                  Sie möchten eine eigene Organisation erstellen?
                </h3>
                <div className="mt-2 text-sm text-gray-500">
                  <p>
                    <span className="font-semibold underline">
                      <Link to="/onboarding">Hier</Link>
                    </span>{" "}
                    können Sie eine eigene Organisation erstellen.
                    <br />
                    Bitte beachten Sie, dass Sie nur in einer Organisation
                    Mitglied sein können.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
