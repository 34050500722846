import { graphql } from "~/gql";

export const UpdateScbaSessionParticipationsBulkMutationDocument = graphql(`
  mutation UpdateScbaSessionParticipationsBulk(
    $input: ScbaSessionParticipationsUpdateBulkInput!
  ) {
    scbaSessionParticipationsUpdateBulk(input: $input) {
      scbaSessionId
    }
  }
`);
