import { graphql } from "~/gql";

export const GroupDetailQueryDocument = graphql(`
  query GroupDetail($groupId: ID!) {
    group(id: $groupId) {
      name
      members {
        id
        firstName
        lastName
        firestations {
          name
        }
      }
    }
  }
`);
