import { graphql } from "~/gql";

export const ExaminationDetailQueryDocument = graphql(`
  query ExaminationDetail($examinationId: ID!) {
    examination(id: $examinationId) {
      examinedAt
      validUntil
      type
    }
  }
`);
