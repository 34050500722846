import { useQuery } from "@apollo/client";
import {
  PencilSquareIcon,
  QrCodeIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { compareDesc, differenceInYears, isPast } from "date-fns";
import { Link, Outlet, useParams } from "react-router-dom";
import DebouncedLoadingTable from "~/components/DebouncedLoadingTable";
import ErrorDisplay from "~/components/ErrorDisplay";
import { FirefighterDetailQueryDocument } from "~/queries/FirefighterDetail.query";
import { germanDate } from "~/util/dates";

export default function FirefighterDetail() {
  const params = useParams();
  const firefighterId = params.firefighterId as string;

  const { error, loading, data } = useQuery(FirefighterDetailQueryDocument, {
    variables: { firefighterId }
  });

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  // Sort examinations so the most valid one is first
  const sortedExaminations = [...(data?.firefighter?.examinations ?? [])].sort(
    (a, b) => compareDesc(new Date(a.validUntil), new Date(b.validUntil))
  );

  // Sort scbaSessions so the latest conducted one is first
  const sortedScbaSessionParticipations = [
    ...(data?.firefighter?.scbaSessionParticipations ?? [])
  ].sort((a, b) =>
    compareDesc(new Date(a.scbaSession.date), new Date(b.scbaSession.date))
  );

  const sortedFirestations = [...(data?.firefighter?.firestations ?? [])].sort(
    (a, b) => a.name.localeCompare(b.name)
  );

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <ul role="list">
          {/* Info */}
          <div className="py-4">
            <div className=" bg-white p-4 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="flex flex-row justify-between">
                {/* Basic Info */}
                <div>
                  <h1 className="text-xl font-semibold text-gray-900">
                    {`${data?.firefighter?.firstName ?? ""} ${
                      data?.firefighter?.lastName ?? ""
                    }`}
                  </h1>
                  {data?.firefighter?.dateOfBirth && (
                    <p className="mt-2 text-sm text-gray-700">
                      Geboren am {germanDate(data.firefighter.dateOfBirth)}{" "}
                      (Alter{" "}
                      {differenceInYears(
                        new Date(),
                        new Date(data.firefighter.dateOfBirth)
                      )}{" "}
                      Jahre)
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* Firestations */}
          <div className="py-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Feuerwehren{" "}
                  <span className="ml-1 font-normal text-gray-400">
                    {data?.firefighter?.firestations.length}
                  </span>
                </h1>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <Link to={`firestations/add`} preventScrollReset>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Zu Feuerwehr hinzufügen
                  </button>
                </Link>
              </div>
            </div>
            <div className="mt-4 flex flex-col">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <DebouncedLoadingTable rowCount={1} loading={loading}>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Löschen</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {sortedFirestations.map((firestation) => {
                            return (
                              <tr key={firestation.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {firestation.name}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <div className="flex flex-row-reverse">
                                    <Link
                                      to={`firestations/${firestation.id}/delete`}
                                      title="Mitgliedschaft löschen"
                                      preventScrollReset
                                    >
                                      <TrashIcon className="h-5 w-5 text-red-600 transition-colors hover:text-red-800" />
                                      <span className="sr-only">
                                        Mitgliedschaft in Feuerwehr{" "}
                                        {firestation.name} löschen
                                      </span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DebouncedLoadingTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ScbaSessions */}
          <div className="py-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Atemschutz-Belastungsübungen{" "}
                  <span className="ml-1 font-normal text-gray-400">
                    {data?.firefighter?.scbaSessionParticipations.length}
                  </span>
                </h1>
              </div>
            </div>
            <div className="mt-4 flex flex-col">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <DebouncedLoadingTable rowCount={1} loading={loading}>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Datum
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Druckverbrauch
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Bearbeiten</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {sortedScbaSessionParticipations.map(
                            (scbaSessionParticipation) => {
                              const { scbaSession } = scbaSessionParticipation;
                              return (
                                <tr key={scbaSession.id}>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-primary-900 sm:pl-6">
                                    <Link
                                      to={`/scbasessions/${scbaSession.id}`}
                                    >
                                      {germanDate(scbaSession.date)}
                                    </Link>
                                  </td>
                                  {scbaSessionParticipation.initialPressure !=
                                    null &&
                                  scbaSessionParticipation.endPressure !=
                                    null ? (
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      {`${
                                        scbaSessionParticipation.initialPressure
                                      } → ${
                                        scbaSessionParticipation.endPressure
                                      } = ${
                                        scbaSessionParticipation.initialPressure -
                                        scbaSessionParticipation.endPressure
                                      }`}
                                    </td>
                                  ) : (
                                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                      —
                                    </td>
                                  )}
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <div className="flex items-center justify-end gap-4">
                                      <Link
                                        to={`scbaSessions/${scbaSession.id}/edit`}
                                        className="text-sky-600 hover:text-sky-900"
                                        title="Teilnahme bearbeiten"
                                        preventScrollReset
                                      >
                                        <PencilSquareIcon className="h-5 w-5 text-sky-600 transition-colors hover:text-sky-800" />
                                        <span className="sr-only">
                                          Bearbeiten,{" "}
                                          {germanDate(scbaSession.date)}
                                        </span>
                                      </Link>
                                      <Link
                                        to={`scbaSessions/${scbaSession.id}/delete`}
                                        title="Teilnahme löschen"
                                        preventScrollReset
                                      >
                                        <TrashIcon className="h-5 w-5 text-red-600 transition-colors hover:text-red-800" />
                                        <span className="sr-only">
                                          Löschen,{" "}
                                          {germanDate(scbaSession.date)}
                                        </span>
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          )}
                        </tbody>
                      </table>
                    </DebouncedLoadingTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Examinations */}
          <div className="py-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Untersuchungen{" "}
                  <span className="ml-1 font-normal text-gray-400">
                    {data?.firefighter?.examinations.length}
                  </span>
                </h1>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <Link to={`examinations/add`} preventScrollReset>
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto"
                  >
                    Neue Untersuchung hinzufügen
                  </button>
                </Link>
              </div>
            </div>
            <div className="mt-4 flex flex-col">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <DebouncedLoadingTable rowCount={1} loading={loading}>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Typ
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Gültig bis
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Datum der Untersuchung
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Gültig</span>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Bearbeiten</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {sortedExaminations.map((examination) => {
                            const validUntil = new Date(examination.validUntil);

                            // Valid until end of day
                            validUntil.setHours(23);
                            validUntil.setMinutes(59);
                            const isValid = !isPast(validUntil);

                            return (
                              <tr key={examination.id}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {examination.type.replace("_", ".")}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm font-medium text-gray-900">
                                  {germanDate(examination.validUntil)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {germanDate(examination.examinedAt)}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {isValid ? (
                                    <span className="inline-flex items-center rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">
                                      Gültig
                                    </span>
                                  ) : (
                                    <span className="inline-flex items-center rounded-full bg-red-100 px-2.5 py-0.5 text-xs font-medium text-red-800">
                                      Abgelaufen
                                    </span>
                                  )}
                                </td>
                                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <div className="flex items-center justify-end gap-4">
                                    <Link
                                      to={`examinations/${examination.id}/edit`}
                                      className="text-sky-600 hover:text-sky-900"
                                      title="Untersuchung bearbeiten"
                                      preventScrollReset
                                    >
                                      <PencilSquareIcon className="h-5 w-5 text-sky-600 transition-colors hover:text-sky-800" />
                                      <span className="sr-only">
                                        Bearbeiten, gültig bis{" "}
                                        {germanDate(examination.validUntil)}
                                      </span>
                                    </Link>
                                    <Link
                                      to={`examinations/${examination.id}/delete`}
                                      title="Untersuchung löschen"
                                      preventScrollReset
                                    >
                                      <TrashIcon className="h-5 w-5 text-red-600 transition-colors hover:text-red-800" />
                                      <span className="sr-only">
                                        Löschen, gültig bis{" "}
                                        {germanDate(examination.validUntil)}
                                      </span>
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DebouncedLoadingTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Edit personal data */}
          <div className="py-4">
            <div className="mt-2 flex flex-wrap gap-2 rounded-lg ">
              <Link to="edit" preventScrollReset>
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                >
                  <PencilSquareIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Persönliche Daten bearbeiten
                </button>
              </Link>
              <Link to="code" preventScrollReset>
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                >
                  <QrCodeIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Code anzeigen
                </button>
              </Link>
              <Link to="delete" preventScrollReset>
                <button
                  type="button"
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
                >
                  <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Einsatzkraft löschen
                </button>
              </Link>
            </div>
          </div>
          {/* Activity Feed 
                <div className="py-4">
                </div>
                */}
        </ul>
      </div>
      <Outlet />
    </>
  );
}
