import { Dialog, Transition } from "@headlessui/react";
import { UserCircleIcon } from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  BoltIcon,
  BuildingLibraryIcon,
  BuildingOfficeIcon,
  UserGroupIcon,
  UserIcon,
  XMarkIcon
} from "@heroicons/react/24/outline";
import { ErrorBoundary } from "@sentry/react";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "~/hooks/useAuth";
import { Role } from "~/types/Role.enum";
import { classNames } from "~/util/class-names";
import { overlap } from "~/util/overlap";
import DebugInfo from "./DebugInfo";

const navigation = [
  {
    name: "Einsatzkräfte",
    href: "/firefighters",
    icon: UserIcon,
    roles: [Role.ADMIN, Role.CHIEF, Role.SCBA_WORKER]
  },
  {
    name: "Gruppen",
    href: "/groups",
    icon: UserGroupIcon,
    roles: [Role.ADMIN, Role.CHIEF]
  },
  {
    name: "Feuerwehren",
    href: "/firestations",
    icon: BuildingOfficeIcon,
    roles: [Role.ADMIN, Role.CHIEF, Role.SCBA_WORKER]
  },
  {
    name: "Belastungsübungen",
    href: "/scbasessions",
    icon: BoltIcon,
    roles: [Role.ADMIN, Role.SCBA_WORKER]
  },
  {
    name: "Organisation",
    href: "/organization",
    icon: BuildingLibraryIcon,
    roles: [Role.ADMIN]
  }
];

type ShellProps = {
  disabled?: boolean;
};

export default function Shell(props: PropsWithChildren<ShellProps>) {
  const location = useLocation();
  const { pathname } = location;
  const { claims, logout } = useAuth();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => setSidebarOpen(false), [location]);

  const roles = claims?.roles ?? [];

  const filteredNavigation = navigation.filter((item) =>
    overlap(roles, item.roles)
  );

  const gqlEndpoint =
    (import.meta.env.VITE_GRAPHQL_ENDPOINT as string | null) ?? "";
  const isDevOrPreview =
    window.location.hostname === "localhost" || gqlEndpoint.includes("api-dev");

  return (
    <ErrorBoundary>
      <div className="h-full">
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute right-0 top-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pb-4 pt-5">
                    <div className="flex flex-shrink-0 items-center px-4">
                      <img
                        src="/acensis.logo.v1.white.svg"
                        className="h-8 w-auto"
                      />
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {filteredNavigation.map((item) => {
                        const current =
                          item.href === "/"
                            ? item.href === pathname
                            : pathname.startsWith(item.href);
                        return (
                          <Link
                            key={item.name}
                            to={props.disabled ? "" : item.href}
                            className={classNames(
                              current
                                ? "bg-gray-900 text-white"
                                : "text-gray-300 hover:bg-gray-700 hover:text-white",
                              "group flex items-center rounded-md px-2 py-2 text-base font-medium",
                              props.disabled ? "cursor-not-allowed" : ""
                            )}
                          >
                            <item.icon
                              className={classNames(
                                current
                                  ? "text-gray-300"
                                  : "text-gray-400 group-hover:text-gray-300",
                                "mr-4 h-6 w-6 flex-shrink-0"
                              )}
                              aria-hidden="true"
                            />
                            {item.name}
                          </Link>
                        );
                      })}
                    </nav>
                  </div>
                  <div className="flex flex-shrink-0 bg-gray-700 p-4">
                    <div className="flex items-center">
                      <div>
                        <Link
                          to={props.disabled ? "" : "/account"}
                          className={classNames(
                            props.disabled ? "cursor-not-allowed" : ""
                          )}
                        >
                          <UserCircleIcon className="h-12 w-12 text-white" />
                        </Link>
                      </div>
                      <div className="ml-3">
                        <p className="text-base font-medium text-white">
                          <Link
                            to={props.disabled ? "" : "/account"}
                            className={classNames(
                              props.disabled ? "cursor-not-allowed" : ""
                            )}
                          >
                            {claims?.name ?? "Mein Konto"}
                          </Link>
                        </p>
                        <button onClick={() => logout()}>
                          <p className="text-sm font-medium text-gray-400 group-hover:text-gray-300">
                            Abmelden
                          </p>
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex min-h-0 flex-1 flex-col bg-gray-800">
            <div className="flex flex-1 flex-col overflow-y-auto pb-4 pt-5">
              <div className="flex flex-shrink-0 items-center justify-center px-4 py-2">
                <Link
                  to={props.disabled ? "" : "/"}
                  className={classNames(
                    props.disabled ? "cursor-not-allowed" : ""
                  )}
                >
                  <img
                    src="/acensis.logo.v1.white.svg"
                    className="h-8 w-auto"
                  />
                </Link>
              </div>
              <nav className="mt-5 flex-1 space-y-1 px-2">
                {filteredNavigation.map((item) => {
                  const current =
                    item.href === "/"
                      ? item.href === pathname
                      : pathname.startsWith(item.href);
                  return (
                    <Link
                      key={item.name}
                      to={props.disabled ? "" : item.href}
                      className={classNames(
                        current
                          ? "bg-gray-900 text-white"
                          : "text-gray-300 hover:bg-gray-700 hover:text-white",
                        "group flex items-center rounded-md px-2 py-2 text-sm font-medium",
                        props.disabled ? "cursor-not-allowed" : ""
                      )}
                    >
                      <item.icon
                        className={classNames(
                          current
                            ? "text-gray-300"
                            : "text-gray-400 group-hover:text-gray-300",
                          "mr-3 h-6 w-6 flex-shrink-0"
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </Link>
                  );
                })}
              </nav>
            </div>
            <div className="flex flex-shrink-0 bg-gray-700 p-4">
              <div className="flex items-center">
                <div>
                  <Link
                    to={props.disabled ? "" : "/account"}
                    className={classNames(
                      props.disabled ? "cursor-not-allowed" : ""
                    )}
                  >
                    <UserCircleIcon className="h-9 w-9 text-white" />
                  </Link>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-white">
                    <Link
                      to={props.disabled ? "" : "/account"}
                      className={classNames(
                        props.disabled ? "cursor-not-allowed" : ""
                      )}
                    >
                      {claims?.name ?? "Mein Konto"}
                    </Link>
                  </p>
                  <button onClick={() => logout()}>
                    <p className="text-xs font-medium text-gray-300 group-hover:text-gray-200">
                      Abmelden
                    </p>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex h-full flex-1 flex-col md:pl-64">
          <div className="sticky top-0 z-10 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <main className="h-full flex-1">
            <div className="h-full py-6">
              <div className="mx-auto h-full max-w-7xl px-4 sm:px-6 md:px-8">
                {props.children}
              </div>
            </div>
          </main>
        </div>
      </div>
      {isDevOrPreview && (
        <div className="fixed bottom-0 right-0 p-4 text-white">
          <DebugInfo />
        </div>
      )}
    </ErrorBoundary>
  );
}
