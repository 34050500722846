import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import SlideOver from "~/components/SlideOver";
import {
  FirefighterDetailDocument,
  FirestationDetailDocument,
  ListAllFirefightersDocument,
  ListAllFirestationsDocument
} from "~/gql/graphql";
import { CreateFirestationMembershipMutationDocument } from "~/mutations/CreateFirestationMembership.mutation";
import { AddFirestationMembershipQueryDocument } from "~/queries/AddFirestationMembership.query";
import { extractCode } from "~/util/error";

type Inputs = {
  firestationId: string;
};

export default function AddFirefighterToFirestation() {
  const [shouldClose, setShouldClose] = useState(false);

  const params = useParams();
  const firefighterId = params.firefighterId as string;

  const {
    error: queryError,
    loading: queryLoading,
    data: queryData
  } = useQuery(AddFirestationMembershipQueryDocument, {
    variables: { firefighterId }
  });

  useEffect(() => {
    if (queryError) {
      const code = extractCode(queryError);
      switch (code) {
        case "NOT_FOUND":
          toast.error("Die Einsatzkraft wurde nicht gefunden.");
          break;
        default:
          toast.error(
            "Unerwarteter Fehler beim Abrufen der verfügbaren Feuerwehren."
          );
          break;
      }
      setShouldClose(true);
    }
  }, [queryError]);

  const [
    createMembership,
    { error: mutationError, loading: mutationLoading, data: mutationData }
  ] = useMutation(CreateFirestationMembershipMutationDocument);

  useEffect(() => {
    if (mutationError) {
      const code = extractCode(mutationError);
      switch (code) {
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, diese Mitgliedschaft anzulegen."
          );
          break;
        case "FIRESTATION_NOT_FOUND":
          toast.error("Die Feuerwehr wurde nicht gefunden.");
          break;
        case "FIREFIGHTER_NOT_FOUND":
          toast.error("Die Einsatzkraft wurde nicht gefunden.");
          break;
        default:
          toast.error("Unerwarteter Fehler beim Anlegen der Mitgliedschaft.");
          break;
      }
    }
  }, [mutationError]);

  useEffect(() => {
    if (!mutationError && mutationData) {
      setShouldClose(true);
    }
  }, [mutationData]);

  const { register, handleSubmit, setValue } = useForm<Inputs>();
  const submitHandler: SubmitHandler<Inputs> = (data) => {
    const { firestationId } = data;
    createMembership({
      variables: { input: { firefighterId, firestationId } },
      refetchQueries: [
        { query: FirefighterDetailDocument, variables: { firefighterId } },
        { query: FirestationDetailDocument, variables: { firestationId } },
        { query: ListAllFirestationsDocument },
        ListAllFirefightersDocument
      ]
    });
  };

  const currentMemberships = (queryData?.firefighter?.firestations ?? []).map(
    (firestation) => firestation.id
  );
  const filteredFirestations = (queryData?.firestations ?? []).filter(
    (firestation) => !currentMemberships.includes(firestation.id)
  );
  filteredFirestations.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    if (filteredFirestations.length > 0) {
      setValue("firestationId", filteredFirestations[0].id);
    }
  }, [filteredFirestations]);

  return (
    <SlideOver
      title="Einsatzkraft zu Feuerwehr hinzufügen"
      shouldClose={shouldClose}
    >
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="firestationId"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Feuerwehr <span className="font-light text-red-600">*</span>
                </label>
                <select
                  id="firestationId"
                  className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required
                  {...register("firestationId")}
                  disabled={
                    filteredFirestations.length === 0 || mutationLoading
                  }
                >
                  {filteredFirestations.map((firestation) => (
                    <option key={firestation.id} value={firestation.id}>
                      {firestation.name}
                    </option>
                  ))}
                  {filteredFirestations.length === 0 && (
                    <option>Keine weiteren Feuerwehren verfügbar</option>
                  )}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 disabled:cursor-not-allowed disabled:bg-gray-600"
            disabled={
              filteredFirestations.length === 0 ||
              queryLoading ||
              mutationLoading
            }
          >
            Hinzufügen
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
