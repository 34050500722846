import { graphql } from "~/gql";

export const ListAllScbaSessionsQueryDocument = graphql(`
  query ListAllScbaSessions {
    scbaSessions {
      id
      date
      numberOfParticipations
    }
  }
`);
