export const numberToPercentageNoFractionDigits = (n: number) =>
  n.toLocaleString("de-DE", { style: "percent", maximumFractionDigits: 0 });

export const isInt = (str: string) => {
  return !isNaN(str as any) && !isNaN(parseInt(str));
};

export const randomIntFromInterval = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
