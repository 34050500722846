import { ApolloError } from "@apollo/client";

export function extractCode(error: ApolloError): string {
  let code = "UNKNOWN_ERROR";
  const codes = (error.graphQLErrors as any as { code?: string }[]).map(
    (graphQLError) => (graphQLError?.code as string) ?? code
  );
  if (codes.length > 0) {
    code = codes[0];
  }
  return code;
}
