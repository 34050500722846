import { XCircleIcon } from "@heroicons/react/20/solid";
import { PropsWithoutRef } from "react";

type Props = {
  title: string;
  code?: string;
  message: string;
};

export default function GenericError(props: PropsWithoutRef<Props>) {
  const { title, code, message } = props;

  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          {code && (
            <div className="mt-2 text-sm text-red-700">
              <span className="rounded-md bg-red-200 p-1 font-mono">
                {code}
              </span>
            </div>
          )}
          <div className="mt-2 text-sm text-red-700">{message}</div>
        </div>
      </div>
    </div>
  );
}
