import { graphql } from "~/gql";

export const ListAllFirefightersQueryDocument = graphql(`
  query ListAllFirefighters($examinationType: ExaminationType!) {
    firefighters {
      id
      firstName
      lastName
      firestations {
        name
      }
      lastScbaSessionParticipation {
        scbaSession {
          date
        }
      }
      hasValidExamination(type: $examinationType)
    }
  }
`);
