import { graphql } from "~/gql";

export const ScbaSessionDetailSimpleQueryDocument = graphql(`
  query ScbaSessionDetailSimple($scbaSessionId: ID!) {
    scbaSession(id: $scbaSessionId) {
      date
      comment
    }
  }
`);
