import { graphql } from "~/gql";

export const ListAllGroupsQueryDocument = graphql(`
  query ListAllGroups {
    groups {
      id
      name
      numberOfMembers
    }
  }
`);
