import { InformationCircleIcon } from "@heroicons/react/24/outline";
import QRCode from "qrcode";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "~/components/Modal";

export default function ShowFirefighterCode() {
  const params = useParams();
  const firefighterId = params.firefighterId as string;

  const [shouldClose, setShouldClose] = useState(false);

  const [data, setData] = useState<string>();
  useEffect(() => {
    QRCode.toDataURL(`https://app.acensis.de/firefighters/${firefighterId}`, {
      errorCorrectionLevel: "high"
    }).then((url) => setData(url));
  }, []);

  const qrCodeReady = data !== undefined;

  return (
    <Modal shouldClose={shouldClose}>
      <div className="flex flex-col items-center justify-center">
        {qrCodeReady ? (
          <>
            <img src={data} />
            <div className="flex text-sm">
              <p className="group inline-flex items-center text-gray-500">
                <InformationCircleIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-2">
                  Zum Drucken:
                  <br />
                  Rechtsklick + "In neuem Tab öffnen"
                </span>
              </p>
            </div>
          </>
        ) : (
          <p>QR-Code lädt...</p>
        )}
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:w-auto"
          onClick={() => setShouldClose(true)}
        >
          Schließen
        </button>
      </div>
    </Modal>
  );
}
