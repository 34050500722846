import { graphql } from "~/gql";

export const DeleteScbaSessionParticipationMutationDocument = graphql(`
  mutation DeleteScbaSessionParticipation(
    $input: ScbaSessionParticipationDeleteInput!
  ) {
    scbaSessionParticipationDelete(input: $input) {
      deletedScbaSessionParticipationScbaSessionId
    }
  }
`);
