import { decodeJwt } from "jose";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Err, Ok, Result } from "ts-results";
import { UserClaims } from "~/types/UserClaims.type";
import {
  createAccount as createAccountHelper,
  getTokens,
  isTokenExpired,
  refreshAccessToken,
  removeRefreshToken
} from "~/util/auth-helpers";
import { AuthContext } from "./AuthContext";

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [claims, setClaims] = useState<UserClaims | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      setClaims(null);
    } else {
      const payload = decodeJwt(accessToken);
      if (payload.claims) {
        setClaims(payload.claims as UserClaims);
      }
    }
  }, [accessToken]);

  const login = useCallback(
    async (
      email: string,
      password: string
    ): Promise<Result<void, "invalid_credentials" | "unknown_error">> => {
      const tokenResult = await getTokens(email, password);
      if (!tokenResult.ok) {
        return tokenResult;
      }
      const { accessToken } = tokenResult.val;
      setAccessToken(accessToken);
      return Ok.EMPTY;
    },
    [setAccessToken]
  );

  const createAccount = useCallback(
    async (
      name: string,
      email: string,
      password: string
    ): Promise<Result<void, "account_creation_failed">> => {
      if (accessToken) {
        console.error("User is already logged in, sign up not possible");
        return new Err("account_creation_failed");
      }
      return createAccountHelper(name, email, password);
    },
    [accessToken]
  );

  const logout = useCallback(async () => {
    setAccessToken(null);
    removeRefreshToken();
    navigate("/login");
  }, []);

  const getAccessToken = useCallback(async () => {
    // Using local variable because state change is not reflected immediately in this function
    let token = accessToken;

    if (!token || (token && isTokenExpired(token))) {
      const refreshedAccessToken = await refreshAccessToken();
      if (refreshedAccessToken) {
        token = refreshedAccessToken;
        setAccessToken(refreshedAccessToken);
      }
    }
    if (!token) {
      navigate("/login");
      return "";
    }
    return token;
  }, [accessToken, setAccessToken]);

  const refreshClaims = useCallback(async () => {
    const refreshedAccessToken = await refreshAccessToken();
    if (!refreshedAccessToken) {
      setAccessToken(null);
      navigate("/login");
    } else {
      setAccessToken(refreshedAccessToken);
    }
  }, [setAccessToken]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!accessToken,
        accessToken,
        claims,
        login,
        createAccount,
        logout,
        getAccessToken,
        refreshClaims
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
