import { graphql } from "~/gql";

export const FirefighterDetailQueryDocument = graphql(`
  query FirefighterDetail($firefighterId: ID!) {
    firefighter(id: $firefighterId) {
      id
      lastName
      firstName
      dateOfBirth
      scbaSessionParticipations {
        initialPressure
        endPressure
        scbaSession {
          id
          date
        }
      }
      examinations {
        id
        type
        validUntil
        examinedAt
      }
      firestations {
        id
        name
      }
      barcodes {
        id
        code
        createdAt
        lastUsedAt
      }
    }
  }
`);
