import { graphql } from "~/gql";

export const DeleteFirestationMembershipMutationDocument = graphql(`
  mutation DeleteFirestationMembership(
    $input: FirestationMembershipDeleteInput!
  ) {
    firestationMembershipDelete(input: $input) {
      deletedFirestationMembershipFirefighterId
    }
  }
`);
