import { ClipboardIcon } from "@heroicons/react/24/outline";
import { BeakerIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useCopyToClipboard } from "usehooks-ts";
import { useAuth } from "~/hooks/useAuth";

export default function DebugInfo() {
  const { claims, getAccessToken } = useAuth();
  const [_, copy] = useCopyToClipboard();
  const [hidden, setHidden] = useState(
    localStorage.getItem("debugInfoHidden") === "true"
  );

  useEffect(() => {
    localStorage.setItem("debugInfoHidden", hidden.toString());
  }, [hidden]);

  if (hidden) {
    return (
      <button
        type="button"
        className="rounded-full bg-primary-600 p-2 text-white shadow-sm transition-colors hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
        onClick={() => setHidden(false)}
      >
        <BeakerIcon className="h-5 w-5" aria-hidden="true" />
      </button>
    );
  }

  return (
    <div className="m-6 bg-orange-100 sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <div className="flex justify-between">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Debug Info
          </h3>
          <div>
            <button onClick={() => setHidden(true)}>
              <XMarkIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="mt-4 text-gray-900">
          <table>
            <tbody>
              {Object.entries(claims ?? {}).map(([key, value]) => (
                <tr key={`debug_${key}`}>
                  <td className="pr-4 font-semibold">{key}</td>
                  <td className="pr-2 font-mono text-sm">
                    {Array.isArray(value) ? value.join(", ") : value}
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        copy(Array.isArray(value) ? value.join(", ") : value)
                      }
                    >
                      <ClipboardIcon
                        className="h-4 w-4 text-gray-600"
                        aria-hidden="true"
                      />
                    </button>
                  </td>
                </tr>
              ))}
              {!claims ? (
                <tr>
                  <td className="col-span-2">No user claims present</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => {
              getAccessToken()
                .then(copy)
                .then(() => toast.success("Copied access token to clipboard"))
                .catch(() =>
                  toast.error("Failed to copy access token to clipboard")
                );
            }}
          >
            Copy Access Token
          </button>
        </div>
      </div>
    </div>
  );
}
