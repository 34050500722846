import { graphql } from "~/gql";

export const CreateFirestationMutationDocument = graphql(`
  mutation CreateFirestation($input: FirestationCreateInput!) {
    firestationCreate(input: $input) {
      firestation {
        id
      }
    }
  }
`);
