import { PropsWithChildren, useEffect, useState } from "react";
import { randomIntFromInterval } from "~/util/numbers";
import LoadingTable from "./LoadingTable";

type DebouncedLoadingTableProps = {
  rowCount?: number;
  loading: boolean;
};

export default function DebouncedLoadingTable(
  props: PropsWithChildren<DebouncedLoadingTableProps>
) {
  const { loading } = props;
  const [minimumLoadingTimeElapsed, setMinimumLoadingTimeElapsed] = useState(
    !loading
  );

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setMinimumLoadingTimeElapsed(true);
      },
      randomIntFromInterval(200, 400)
    );

    return () => {
      clearTimeout(timeout);
    };
  }, [setMinimumLoadingTimeElapsed]);

  const showContent = !loading && minimumLoadingTimeElapsed;

  if (showContent) {
    return props.children;
  } else {
    return <LoadingTable rowCount={props.rowCount ?? 5} />;
  }
}
