import { useQuery } from "@apollo/client";
import { Link, Outlet } from "react-router-dom";
import DebouncedLoadingTable from "~/components/DebouncedLoadingTable";
import ErrorDisplay from "~/components/ErrorDisplay";
import { OrganizationDetailQueryDocument } from "~/queries/OrganizationDetail.query";
import { Role } from "~/types/Role.enum";
import { RoleUtil } from "~/util/roles";

export default function OrganizationIndex() {
  const { error, loading, data } = useQuery(OrganizationDetailQueryDocument);

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <ul role="list" className="gap-16">
          {/* Info */}
          <div className="py-4">
            <div className=" bg-white p-4 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div className="flex flex-row justify-between">
                {/* Basic Info */}
                <div>
                  <h1 className="text-xl font-semibold text-gray-900">
                    {data?.organization?.name ?? "Organisation"}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="py-4">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-base font-semibold leading-6 text-gray-900">
                  Benutzer
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Hier können Sie Benutzer:innen zu Ihrer Organisation
                  hinzufügen und deren Rollen verwalten.
                </p>
              </div>
              <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                <Link to="invite" preventScrollReset>
                  <button
                    type="button"
                    className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
                  >
                    Benutzer einladen
                  </button>
                </Link>
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <DebouncedLoadingTable rowCount={3} loading={loading}>
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              E-Mail Adresse
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Rollen
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {(data?.users ?? []).map((user) => (
                            <tr key={user.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {user.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.email}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {user.roles
                                  .map((role) => role.toUpperCase())
                                  .map((role) =>
                                    RoleUtil.toDisplayString(role as Role)
                                  )
                                  .join(", ")}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to={`user/${user.id}`}
                                  className="text-primary-600 hover:text-primary-900"
                                >
                                  Bearbeiten
                                  <span className="sr-only">, {user.name}</span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </DebouncedLoadingTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ul>
      </div>
      <Outlet />
    </>
  );
}
