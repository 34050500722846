import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import ErrorDisplay from "~/components/ErrorDisplay";
import NotFound from "~/components/NotFound";
import SlideOver from "~/components/SlideOver";
import Spinner from "~/components/Spinner";
import { FirefighterDetailDocument } from "~/gql/graphql";
import { UpdateFirefighterMutationDocument } from "~/mutations/UpdateFirefighter.mutation";
import { FirefighterDetailQueryDocument } from "~/queries/FirefighterDetail.query";
import { dateToHtmlDateInput } from "~/util/dates";

type Inputs = {
  firstName?: string;
  lastName?: string;
  dateOfBirth?: Date;
};

export default function EditFirefighter() {
  const params = useParams();
  const firefighterId = params.firefighterId as string;

  const {
    error: queryError,
    loading: queryLoading,
    data: queryData
  } = useQuery(FirefighterDetailQueryDocument, {
    variables: { firefighterId }
  });

  const [shouldClose, setShouldClose] = useState(false);
  const { register, handleSubmit } = useForm<Inputs>();
  const [
    updateFirefighter,
    { error: mutationError, loading: mutationLoading, data: mutationData }
  ] = useMutation(UpdateFirefighterMutationDocument, {
    refetchQueries: [
      { query: FirefighterDetailDocument, variables: { firefighterId } }
    ]
  });

  const submitHandler: SubmitHandler<Inputs> = (formData) => {
    updateFirefighter({
      variables: { input: { id: firefighterId, ...formData } }
    });
  };

  useEffect(() => {
    if (!mutationError && mutationData) {
      setShouldClose(true);
    }
  }, [mutationLoading]);

  if (queryError) {
    return <ErrorDisplay error={queryError} />;
  }

  if (queryLoading) {
    return (
      <SlideOver title="Einsatzkraft bearbeiten" shouldClose={shouldClose}>
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </SlideOver>
    );
  }

  if (!queryData || !queryData.firefighter) {
    return <NotFound />;
  }

  const { firefighter } = queryData;

  return (
    <SlideOver title="Einsatzkraft bearbeiten" shouldClose={shouldClose}>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Vorname <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="firstName"
                    autoComplete="off"
                    required
                    defaultValue={firefighter.firstName}
                    {...register("firstName")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nachname <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    id="lastName"
                    autoComplete="off"
                    required
                    defaultValue={firefighter.lastName}
                    {...register("lastName")}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="dateOfBirth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Geburtsdatum
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  autoComplete="none"
                  defaultValue={
                    firefighter.dateOfBirth &&
                    dateToHtmlDateInput(new Date(firefighter.dateOfBirth))
                  }
                  {...register("dateOfBirth", { valueAsDate: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            disabled={mutationLoading}
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          >
            Speichern
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
