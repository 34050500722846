import { useMutation } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { SyntheticEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Modal from "~/components/Modal";
import { ListAllFirefightersDocument } from "~/gql/graphql";
import { useAuth } from "~/hooks/useAuth";
import { DeleteUserMutationDocument } from "~/mutations/DeleteUser.mutation";
import { extractCode } from "~/util/error";

export default function DeleteAccount() {
  const [shouldClose, setShouldClose] = useState(false);
  const { claims } = useAuth();

  const logout = () => console.error("Logout not implemented");

  const [deleteFirefighter, { error, loading, data }] = useMutation(
    DeleteUserMutationDocument,
    {
      variables: { input: { id: claims?.userId ?? "error" } },
      refetchQueries: [ListAllFirefightersDocument]
    }
  );

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    deleteFirefighter();
  };

  useEffect(() => {
    if (data && !loading && !error) {
      logout();
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      const code = extractCode(error);
      switch (code) {
        case "FORBIDDEN":
          toast.error("Sie sind nicht berechtigt, dieses Konto zu löschen.");
          break;
        case "NOT_FOUND":
          toast.error("Das zu löschende Konto wurde nicht gefunden.");
          break;
        case "USER_IS_ONLY_ADMIN":
          toast.error(
            "Das Konto kann nicht gelöscht werden, da es der einzige Administrator ist."
          );
          break;
        default:
          toast.error("Unerwarteter Fehler beim Löschen des Kontos.");
          break;
      }
    }
  }, [error]);

  return (
    <Modal shouldClose={shouldClose}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Konto löschen
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Soll Ihr Konto wirklich gelöscht werden? Diese Aktion kann nicht
              rückgängig gemacht werden.
            </p>
          </div>
        </div>
      </div>
      <form
        className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
        onSubmit={handleSubmit}
      >
        <button
          type="submit"
          disabled={loading}
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Löschen
        </button>
        <button
          type="button"
          onClick={() => setShouldClose(true)}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Abbrechen
        </button>
      </form>
    </Modal>
  );
}
