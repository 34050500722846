import { graphql } from "~/gql";

export const ListAllFirestationsQueryDocument = graphql(`
  query ListAllFirestations {
    firestations {
      id
      name
      numberOfFirefighters
    }
  }
`);
