import { graphql } from "~/gql";

export const UpdateGroupMutationDocument = graphql(`
  mutation UpdateGroup($input: GroupUpdateInput!) {
    groupUpdate(input: $input) {
      group {
        id
      }
    }
  }
`);
