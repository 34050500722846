import { graphql } from "~/gql";

export const CreateGroupMutationDocument = graphql(`
  mutation CreateGroup($input: GroupCreateInput!) {
    groupCreate(input: $input) {
      group {
        id
      }
    }
  }
`);
