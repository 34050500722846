import { createContext } from "react";
import { Result } from "ts-results";
import { UserClaims } from "~/types/UserClaims.type";

export type AuthContextType = {
  isAuthenticated: boolean;
  accessToken: string | null;
  login: (
    email: string,
    password: string
  ) => Promise<Result<void, "invalid_credentials" | "unknown_error">>;
  createAccount: (
    name: string,
    email: string,
    password: string
  ) => Promise<Result<void, "account_creation_failed">>;
  logout: () => void;
  getAccessToken: () => Promise<string>;
  refreshClaims: () => Promise<void>;
  claims: UserClaims | null;
};

const authContextMissing = () => {
  throw new Error("AuthContext missing");
};

export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  accessToken: null,
  login: authContextMissing,
  createAccount: authContextMissing,
  logout: authContextMissing,
  getAccessToken: authContextMissing,
  refreshClaims: authContextMissing,
  claims: null
});
