import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "~/hooks/useAuth";

export default function ForceOnboarding(props: PropsWithChildren) {
  const { claims } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (
      claims &&
      !claims.organizationId &&
      !location.pathname.startsWith("/onboarding")
    ) {
      // User not onboarded
      navigate("/onboarding");
    }
  }, [claims]);

  return props.children;
}
