import { graphql } from "~/gql";

export const AddFirestationMembershipQueryDocument = graphql(`
  query AddFirestationMembership($firefighterId: ID!) {
    firestations {
      id
      name
    }
    firefighter(id: $firefighterId) {
      firestations {
        id
      }
    }
  }
`);
