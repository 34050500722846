import { useMutation } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "~/components/Modal";
import { ListAllFirefightersDocument } from "~/gql/graphql";
import { DeleteFirefighterMutationDocument } from "~/mutations/DeleteFirefighter.mutation";

export default function DeleteFirefighter() {
  const [shouldClose, setShouldClose] = useState(false);

  const params = useParams();
  const firefighterId = params.firefighterId as string;

  const [deleteFirefighter, { error, loading, data }] = useMutation(
    DeleteFirefighterMutationDocument,
    {
      variables: { input: { id: firefighterId } },
      refetchQueries: [ListAllFirefightersDocument]
    }
  );

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    deleteFirefighter();
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (data && !loading && !error) {
      navigate("/firefighters");
    }
  }, [data]);

  return (
    <Modal shouldClose={shouldClose}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Einsatzkraft löschen
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Soll die Einsatzkraft wirklich gelöscht werden? Diese Aktion kann
              nicht rückgängig gemacht werden.
            </p>
          </div>
        </div>
      </div>
      <form
        className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
        onSubmit={handleSubmit}
      >
        <button
          type="submit"
          disabled={loading}
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Löschen
        </button>
        <button
          type="button"
          onClick={() => setShouldClose(true)}
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Abbrechen
        </button>
      </form>
    </Modal>
  );
}
