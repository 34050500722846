import { graphql } from "~/gql";

export const CreateScbaSessionParticipationMutationDocument = graphql(`
  mutation CreateScbaSessionParticipation(
    $input: ScbaSessionParticipationCreateInput!
  ) {
    scbaSessionParticipationCreate(input: $input) {
      scbaSessionParticipation {
        initialPressure
      }
    }
  }
`);
