import { graphql } from "~/gql";

export const CreateScbaSessionMutationDocument = graphql(`
  mutation CreateScbaSession($input: ScbaSessionCreateInput!) {
    scbaSessionCreate(input: $input) {
      scbaSession {
        id
      }
    }
  }
`);
