import { ApolloError } from "@apollo/client";
import { PropsWithoutRef } from "react";
import GenericError from "./errors/GenericError";
import Unauthorized from "./errors/Unauthorized";

export default function ErrorDisplay(
  props: PropsWithoutRef<{ error: ApolloError }>
) {
  const { error } = props;

  if (
    error.message.includes("Unauthorized") ||
    error.message.includes("Forbidden")
  ) {
    return <Unauthorized />;
  }

  return (
    <GenericError
      title="Unerwarteter Fehler"
      code={error.message}
      message="Bitte versuchen Sie es erneut. Sollte der Fehler bestehen, so wenden
            Sie sich bitte an den Support."
    />
  );
}
