import { useQuery } from "@apollo/client";
import { Link, Outlet } from "react-router-dom";
import DebouncedLoadingTable from "~/components/DebouncedLoadingTable";
import ErrorDisplay from "~/components/ErrorDisplay";
import { ExaminationType } from "~/gql/graphql";
import { ListAllFirefightersQueryDocument } from "~/queries/ListAllFirefighters.query";
import { germanDate } from "~/util/dates";

export default function FirefightersIndex() {
  const { error, loading, data } = useQuery(ListAllFirefightersQueryDocument, {
    variables: { examinationType: ExaminationType.G26_3 }
  });

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  const sortedFirefighters = [...(data?.firefighters ?? [])].sort((a, b) =>
    a.lastName.localeCompare(b.lastName)
  );

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">
              Einsatzkräfte{" "}
              <span className="ml-1 font-normal text-gray-400">
                {data?.firefighters.length ?? ""}
              </span>
            </h1>
            <p className="mt-2 text-sm text-gray-700">{/* Subtitle */}</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Link to="/firefighters/new" preventScrollReset>
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sky-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:w-auto"
              >
                Neue Einsatzkraft anlegen
              </button>
            </Link>
          </div>
        </div>
        <div className="mt-8">
          <div className="flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <DebouncedLoadingTable loading={loading}>
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Feuerwehren
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Letzte Belastungsübung
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            G26
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {sortedFirefighters.map((firefighter) => {
                          const name = `${firefighter.lastName}, ${firefighter.firstName}`;
                          const lastScbaSessionDate =
                            firefighter.lastScbaSessionParticipation
                              ? germanDate(
                                  firefighter.lastScbaSessionParticipation
                                    .scbaSession.date
                                )
                              : "—";
                          const stationNames =
                            firefighter.firestations.length > 0
                              ? firefighter.firestations
                                  .map((s) => s.name)
                                  .join(", ")
                              : "—";

                          return (
                            <tr key={firefighter.id}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                {name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {stationNames}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {lastScbaSessionDate}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {firefighter.hasValidExamination ? (
                                  <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                                    Gültig
                                  </span>
                                ) : (
                                  <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
                                    Ungültig
                                  </span>
                                )}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to={`/firefighters/${firefighter.id}`}
                                  className="text-sky-600 hover:text-sky-900"
                                >
                                  Details
                                  <span className="sr-only">, {name}</span>
                                </Link>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </DebouncedLoadingTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
