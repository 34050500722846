import { useMutation } from "@apollo/client";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { SyntheticEvent, useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Modal from "~/components/Modal";
import {
  FirefighterDetailDocument,
  ScbaSessionDetailDocument
} from "~/gql/graphql";
import { DeleteScbaSessionParticipationMutationDocument } from "~/mutations/DeleteScbaSessionParticipation.mutation";
import { extractCode } from "~/util/error";

export default function DeleteScbaSessionParticipation() {
  const params = useParams();
  const scbaSessionId = params.scbaSessionId as string;
  const firefighterId = params.firefighterId as string;

  const [shouldClose, setShouldClose] = useState(false);

  const [deleteParticipation, { error }] = useMutation(
    DeleteScbaSessionParticipationMutationDocument,
    {
      variables: { input: { scbaSessionId, firefighterId } },
      refetchQueries: [
        { query: ScbaSessionDetailDocument, variables: { scbaSessionId } },
        {
          query: FirefighterDetailDocument,
          variables: { firefighterId }
        }
      ]
    }
  );

  useEffect(() => {
    if (error) {
      const code = extractCode(error);
      switch (code) {
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, die Teilnahme an dieser Belastungsübung zu löschen."
          );
          break;
        case "NOT_FOUND":
          toast.error(
            "Die Teilnahme an der Belastungsübung wurde nicht gefunden."
          );
          break;
        default:
          toast.error("Unerwarteter Fehler beim Löschen der Teilnahme.");
          break;
      }
    }
  }, [error]);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    deleteParticipation()
      .catch(() => {})
      .finally(() => setShouldClose(true));
  };

  return (
    <Modal shouldClose={shouldClose}>
      <div className="sm:flex sm:items-start">
        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
          <ExclamationTriangleIcon
            className="h-6 w-6 text-red-600"
            aria-hidden="true"
          />
        </div>
        <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3 className="text-base font-semibold leading-6 text-gray-900">
            Teilnahme an Belastungsübung löschen
          </h3>
          <div className="mt-2">
            <p className="text-sm text-gray-500">
              Soll die Teilnahme an der Belastungsübung wirklich gelöscht
              werden? Diese Aktion kann nicht rückgängig gemacht werden.
            </p>
          </div>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
      >
        <button
          type="submit"
          className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Löschen
        </button>
        <button
          type="button"
          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          onClick={() => setShouldClose(true)}
        >
          Abbrechen
        </button>
      </form>
    </Modal>
  );
}
