import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import ErrorDisplay from "~/components/ErrorDisplay";
import NotFound from "~/components/NotFound";
import SlideOver from "~/components/SlideOver";
import Spinner from "~/components/Spinner";
import { UserDetailQueryDocument } from "~/queries/UserDetail.query";

export default function EditOrganizationUser() {
  const params = useParams();
  const userId = params.userId as string;

  const { loading, error, data } = useQuery(UserDetailQueryDocument, {
    variables: { userId }
  });

  if (loading) {
    return (
      <SlideOver title="Benutzer:in bearbeiten">
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      </SlideOver>
    );
  }

  if (error) {
    return (
      <SlideOver title="Benutzer:in bearbeiten">
        <ErrorDisplay error={error} />
      </SlideOver>
    );
  }

  if (!data || !data.user) {
    return (
      <SlideOver title="Benutzer:in bearbeiten">
        <NotFound />
      </SlideOver>
    );
  }

  const { user } = data;

  return (
    <SlideOver title="Benutzer:in bearbeiten">
      <div className="flex flex-1 flex-col justify-between gap-8">
        <div className="divide-y divide-gray-200 px-4 sm:px-6">
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              E-Mail Adresse
            </label>
            <div className="mt-2">
              <input
                type="email"
                name="email"
                id="email"
                defaultValue={user.email}
                disabled
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200 sm:text-sm sm:leading-6"
                placeholder={user.email}
              />
            </div>
          </div>
        </div>
      </div>
    </SlideOver>
  );
}
