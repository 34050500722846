import { graphql } from "~/gql";

export const UpdateScbaSessionParticipationMutationDocument = graphql(`
  mutation UpdateScbaSessionParticipation(
    $input: ScbaSessionParticipationUpdateInput!
  ) {
    scbaSessionParticipationUpdate(input: $input) {
      scbaSessionParticipation {
        initialPressure
      }
    }
  }
`);
