import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { unstable_Blocker as Blocker } from "react-router-dom";
import Modal from "./Modal";

export default function ConfirmNavigation({ blocker }: { blocker: Blocker }) {
  const afterModalLeave = () => blocker.reset?.();

  if (blocker.state === "blocked") {
    return (
      <Modal afterLeave={afterModalLeave} preventUpwardNavigation>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-orange-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3 className="text-base font-semibold leading-6 text-gray-900">
              Ungespeicherte Änderungen
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Sie haben Änderungen vorgenommen und noch nicht gespeichert.
                Wenn Sie die Seite verlassen gehen diese verloren.
              </p>
            </div>
          </div>
        </div>
        <form
          onSubmit={() => blocker.proceed?.()}
          className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse"
        >
          <button
            type="submit"
            className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
          >
            Änderungen verwerfen
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
            onClick={() => blocker.reset?.()}
          >
            Zurück
          </button>
        </form>
      </Modal>
    );
  }

  return <></>;
}
