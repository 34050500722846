import { Role } from "~/types/Role.enum";

export class RoleUtil {
  private static mapping = {
    [Role.ADMIN]: "Administrator",
    [Role.CHIEF]: "Kommandant:in",
    [Role.MAINTENANCE_EMPLOYEE]: "Gerätewart:in",
    [Role.SCBA_WORKER]: "Atemschutzmitarbeiter:in"
  };

  static toDisplayString(role: Role): string {
    return this.mapping[role];
  }
}
