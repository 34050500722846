import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import SlideOver from "~/components/SlideOver";
import Spinner from "~/components/Spinner";
import {
  FirefighterDetailDocument,
  ScbaSessionDetailDocument,
  ScbaSessionParticipationDetailDocument
} from "~/gql/graphql";
import { UpdateScbaSessionParticipationMutationDocument } from "~/mutations/UpdateScbaSessionParticipation.mutation";
import { ScbaSessionParticipationDetailQueryDocument } from "~/queries/ScbaSessionParticipationDetail.query";
import { extractCode } from "~/util/error";

type Inputs = {
  initialPressure: number;
  endPressure: number;
};

export default function EditScbaSessionParticipation() {
  const [shouldClose, setShouldClose] = useState(false);

  const params = useParams();
  const firefighterId = params.firefighterId as string;
  const scbaSessionId = params.scbaSessionId as string;

  const {
    error: queryError,
    loading: queryLoading,
    data: queryData
  } = useQuery(ScbaSessionParticipationDetailQueryDocument, {
    variables: { firefighterId, scbaSessionId }
  });

  useEffect(() => {
    if (queryError) {
      const code = extractCode(queryError);
      switch (code) {
        case "NOT_FOUND":
          toast.error(
            "Die Teilnahme an der Belastungsübung wurde nicht gefunden."
          );
          break;
        default:
          toast.error(
            "Unerwarteter Fehler beim Abrufen der Teilnahme an der Belastungsübung"
          );
          break;
      }
      setShouldClose(true);
    }
  }, [queryError]);

  const [
    updateSessionParticipation,
    { error: mutationError, loading: mutationLoading }
  ] = useMutation(UpdateScbaSessionParticipationMutationDocument, {
    refetchQueries: [
      { query: FirefighterDetailDocument, variables: { firefighterId } },
      { query: ScbaSessionDetailDocument, variables: { scbaSessionId } },
      {
        query: ScbaSessionParticipationDetailDocument,
        variables: { firefighterId, scbaSessionId }
      }
    ]
  });

  useEffect(() => {
    if (mutationError) {
      const code = extractCode(mutationError);
      switch (code) {
        case "FORBIDDEN":
          toast.error(
            "Sie sind nicht berechtigt, die Teilnahme an dieser Belastungsübung zu bearbeiten."
          );
          break;
        case "NOT_FOUND":
          toast.error(
            "Die Teilnahme an dieser Belastungsübung wurde nicht gefunden."
          );
          break;
        default:
          toast.error(
            "Unerwarteter Fehler beim Bearbeiten der Teilnahme an der Belastungsübung."
          );
          break;
      }
    }
  }, [mutationError]);

  const { handleSubmit, register } = useForm<Inputs>();

  const submitHandler: SubmitHandler<Inputs> = (formData) => {
    updateSessionParticipation({
      variables: { input: { firefighterId, scbaSessionId, ...formData } }
    }).finally(() => setShouldClose(true));
  };

  return (
    <SlideOver
      title="Teilnahme an Atemschutz-Belastungsübung bearbeiten"
      shouldClose={shouldClose}
    >
      {queryLoading ? (
        <div className="flex h-full w-full items-center justify-center">
          <Spinner />
        </div>
      ) : (
        <form onSubmit={handleSubmit(submitHandler)}>
          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="space-y-6 pb-5 pt-6">
                <div>
                  <label
                    htmlFor="initialPressure"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Druck Beginn{" "}
                    <span className="font-light text-red-600">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      id="initialPressure"
                      autoComplete="off"
                      placeholder="300-0"
                      min={0}
                      max={330}
                      defaultValue={
                        queryData?.scbaSessionParticipation?.initialPressure ??
                        ""
                      }
                      required
                      {...register("initialPressure", { valueAsNumber: true })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 md:w-1/2"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="endPressure"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Druck Ende{" "}
                    <span className="font-light text-red-600">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="number"
                      id="endPressure"
                      autoComplete="off"
                      placeholder="300-0"
                      min={0}
                      max={330}
                      defaultValue={
                        queryData?.scbaSessionParticipation?.endPressure ?? ""
                      }
                      required
                      {...register("endPressure", { valueAsNumber: true })}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6 md:w-1/2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-1 flex-col justify-between">
            <div className="divide-y divide-gray-200 px-4 sm:px-6">
              <div className="pb-6 pt-4">
                <div className="text-sm text-gray-500">
                  Mit <span className="font-light text-red-600">*</span>{" "}
                  gekennzeichnete Felder sind Pflichtfelder.
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="submit"
              disabled={queryLoading || mutationLoading}
              className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            >
              Änderungen speichern
            </button>
          </div>
        </form>
      )}
    </SlideOver>
  );
}
