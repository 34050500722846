import { graphql } from "~/gql";

export const CreateFirefighterMutationDocument = graphql(`
  mutation CreateFirefighter($input: FirefighterCreateInput!) {
    firefighterCreate(input: $input) {
      firefighter {
        id
      }
    }
  }
`);
