import { useMemo } from "react";

type LoadingTableProps = {
  rowCount?: number;
};

export default function LoadingTable(props: LoadingTableProps) {
  const loadingTableId = useMemo(
    () => Math.random().toString(36).substring(7),
    []
  );
  const numberOfRows = props.rowCount ?? 4;
  const arr = [...Array(numberOfRows)];
  const rowContents = [
    <>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 w-1/2 animate-pulse rounded-md bg-slate-200"></div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 w-4/5 animate-pulse rounded-md bg-slate-300"></div>
      </td>
    </>,
    <>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 animate-pulse rounded-md bg-slate-300"></div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 w-1/2 animate-pulse rounded-md bg-slate-200"></div>
      </td>
    </>,
    <>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 w-4/5 animate-pulse rounded-md bg-slate-200"></div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 w-2/3 animate-pulse rounded-md bg-slate-300"></div>
      </td>
    </>,
    <>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 animate-pulse rounded-md bg-slate-300"></div>
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
        <div className="col-span-2 h-3 w-1/2 animate-pulse rounded-md bg-slate-200"></div>
      </td>
    </>
  ];

  const getRow = (i: number) => (
    <tr key={`loading-table-${loadingTableId}-row-${i}`}>
      {rowContents[i % rowContents.length]}
    </tr>
  );

  return (
    <div className="flex flex-col">
      <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <div className="col-span-2 h-3 animate-pulse rounded-md bg-slate-300"></div>
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <div className="col-span-2 h-3 animate-pulse rounded-md bg-slate-200"></div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {arr.map((_, i) => getRow(i))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
