import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import CommandPalette, {
  CommandPaletteOption
} from "~/components/CommandPalette";
import {
  FirefighterDetailDocument,
  ScbaSessionDetailDocument
} from "~/gql/graphql";
import { CreateScbaSessionParticipationMutationDocument } from "~/mutations/CreateScbaSessionParticipation.mutation";
import { AddFirefighterToScbaSessionQueryDocument } from "~/queries/AddFirefighterToScbaSession.query";
import { extractCode } from "~/util/error";

export default function AddFirefighterToScbaSession() {
  const params = useParams();
  const scbaSessionId = params.scbaSessionId as string;

  const [shouldClose, setShouldClose] = useState(false);

  const { error: queryError, data: queryData } = useQuery(
    AddFirefighterToScbaSessionQueryDocument,
    {
      variables: { scbaSessionId }
    }
  );

  useEffect(() => {
    if (queryError) {
      const code = extractCode(queryError);
      switch (code) {
        case "FORBIDDEN":
          toast.error("Sie sind nicht berechtigt, Einsatzkräfte hinzuzufügen.");
          break;
        default:
          toast.error("Unerwarteter Fehler beim Abrufen der Einsatzkräfte.");
          break;
      }
      setShouldClose(true);
    }
  }, [queryError]);

  const alreadyParticipatingFirefighters = (
    queryData?.scbaSession?.participations ?? []
  ).map((p) => p.firefighter.id);

  const filteredFirefighters = (queryData?.firefighters ?? []).filter(
    (f) => !alreadyParticipatingFirefighters.includes(f.id)
  );

  const options: CommandPaletteOption[] = filteredFirefighters.map(
    (firefighter) => {
      const firestationSuffix = firefighter.firestations
        .map((firestation) => firestation.name)
        .join(", ");

      return {
        id: firefighter.id,
        title: `${firefighter.lastName}, ${firefighter.firstName}`,
        details: firestationSuffix
      };
    }
  );

  const [addFirefighterToSession] = useMutation(
    CreateScbaSessionParticipationMutationDocument
  );

  const handleSelection = (option: CommandPaletteOption) => {
    const firefighterId = option.id;

    addFirefighterToSession({
      variables: { input: { scbaSessionId, firefighterId } },
      refetchQueries: [
        { query: ScbaSessionDetailDocument, variables: { scbaSessionId } },
        {
          query: FirefighterDetailDocument,
          variables: { firefighterId }
        }
      ]
    })
      .catch(() => toast.error("Fehler beim Hinzufügen der Einsatzkraft"))
      .finally(() => setShouldClose(true));
  };

  return (
    <CommandPalette
      options={options}
      onSelection={handleSelection}
      shouldClose={shouldClose}
      allowOnlyOneSelection
      placeholder="Einsatzkraft suchen..."
    />
  );
}
