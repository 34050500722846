import { useMutation, useQuery } from "@apollo/client";
import {
  CheckCircleIcon,
  ClockIcon,
  CloudArrowUpIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon
} from "@heroicons/react/24/outline";
import { toast } from "react-hot-toast";
import {
  Link,
  Outlet,
  unstable_useBlocker as useBlocker,
  useParams
} from "react-router-dom";
import { useMap } from "usehooks-ts";
import ConfirmNavigation from "~/components/ConfirmNavigation";
import ErrorDisplay from "~/components/ErrorDisplay";
import NotFound from "~/components/NotFound";
import Spinner from "~/components/Spinner";
import {
  FirefighterDetailDocument,
  ScbaSessionDetailDocument
} from "~/gql/graphql";
import { useBrowserBlocker } from "~/hooks/useBrowserBlocker";
import { UpdateScbaSessionParticipationsBulkMutationDocument } from "~/mutations/UpdateScbaSessionParticipationsBulk.mutation";
import { ScbaSessionDetailQueryDocument } from "~/queries/ScbaSessionDetail.query";
import { germanDate, germanTime } from "~/util/dates";
import { isInt } from "~/util/numbers";

export default function ScbaSessionDetail() {
  const params = useParams();
  const scbaSessionId = params.scbaSessionId as string;

  const [changeMap, changeMapActions] = useMap<string, number>();

  const hasOutstandingChanges = changeMap.size > 0;

  const blocker = useBlocker(hasOutstandingChanges);
  useBrowserBlocker(hasOutstandingChanges);

  const [updateParticipation, { loading: mutationLoading }] = useMutation(
    UpdateScbaSessionParticipationsBulkMutationDocument
  );

  const applyChanges = () => {
    if (changeMap.size === 0) return;

    const updates = [];
    const affectedFirefighterIds = new Set<string>();

    for (const [field, value] of changeMap) {
      const [firefighterId, fieldType] = field.split("_");

      if (!affectedFirefighterIds.has(firefighterId)) {
        affectedFirefighterIds.add(firefighterId);
      }

      switch (fieldType) {
        case "initialPressure":
        case "endPressure":
          updates.push({ firefighterId, [fieldType]: value });
          break;
        default:
          break;
      }
    }

    updateParticipation({
      variables: { input: { scbaSessionId, updates } },
      refetchQueries: [
        { query: ScbaSessionDetailDocument, variables: { scbaSessionId } },
        ...Array.from(affectedFirefighterIds).map((firefighterId) => ({
          query: FirefighterDetailDocument,
          variables: { firefighterId }
        }))
      ]
    })
      .catch(() => toast.error("Fehler beim Speichern der Änderungen"))
      .finally(() => changeMapActions.reset());
  };

  const updateValue = (
    firefighterId: string,
    valueType: "initialPressure" | "endPressure",
    value: string
  ) => {
    if (!isInt(value)) return;
    changeMapActions.set(`${firefighterId}_${valueType}`, parseInt(value));
  };

  const { error, loading, data } = useQuery(ScbaSessionDetailQueryDocument, {
    variables: { scbaSessionId }
  });

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (loading || !data) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Spinner />
      </div>
    );
  }

  const { scbaSession } = data;

  if (!scbaSession) {
    return <NotFound />;
  }

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <ul role="list">
          {/* Info */}
          <div className="py-4">
            <div className="flex flex-wrap justify-between gap-4 bg-white p-4 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <div>
                <h1 className="text-xl text-gray-900">
                  Belastungsübung am{" "}
                  <span className="font-semibold">
                    {germanDate(scbaSession.date)}
                  </span>{" "}
                  um{" "}
                  <span className="font-semibold">
                    {germanTime(scbaSession.date)}
                  </span>
                </h1>
                {scbaSession.comment && (
                  <>
                    <p className="mt-2 text-sm text-gray-700">Kommentar:</p>
                    <p className="mt-2 text-sm text-gray-700">
                      {scbaSession.comment}
                    </p>
                  </>
                )}
              </div>

              <div>
                <Link to="addfirefighter" preventScrollReset>
                  <button
                    type="button"
                    className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
                  >
                    <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                    Einsatzkraft hinzufügen
                  </button>
                </Link>
              </div>
            </div>
          </div>

          <div className="flex items-center space-x-2 pt-2">
            {mutationLoading ? (
              <>
                <ClockIcon className="h-5 w-5 text-orange-500" />
                <span className="text-orange-500">
                  Änderungen werden gespeichert...
                </span>
              </>
            ) : hasOutstandingChanges ? (
              <div className="flex flex-wrap items-center gap-4">
                <div className="flex items-center gap-2">
                  <ClockIcon className="h-5 w-5 text-gray-500" />
                  <span className="text-gray-500">
                    Ausstehende Änderungen...
                  </span>
                </div>
                <button
                  type="button"
                  onClick={applyChanges}
                  disabled={mutationLoading}
                  className="inline-flex items-center gap-x-1.5 rounded-md bg-teal-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-teal-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-teal-600"
                >
                  <CloudArrowUpIcon
                    className="-ml-0.5 h-5 w-5"
                    aria-hidden="true"
                  />
                  Speichern
                </button>
              </div>
            ) : (
              <>
                <CheckCircleIcon className="h-5 w-5 text-green-500" />
                <span className="text-green-500">Änderungen gespeichert</span>
              </>
            )}
          </div>

          <div className="py-4">
            <div className="flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Druck Beginn
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Druck Ende
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {scbaSession.participations.map((participation) => (
                        <tr
                          key={`${scbaSession.id}_${participation.firefighter.id}`}
                        >
                          <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                            {participation.firefighter.lastName},{" "}
                            {participation.firefighter.firstName}
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                            <input
                              type="number"
                              name={`${scbaSession.id}_${participation.firefighter.id}_initialPressure`}
                              autoComplete="off"
                              placeholder="300-0"
                              required
                              onChange={(e) =>
                                updateValue(
                                  participation.firefighter.id,
                                  "initialPressure",
                                  e.currentTarget.value
                                )
                              }
                              defaultValue={participation.initialPressure ?? ""}
                              className="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </td>
                          <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-500">
                            <input
                              type="number"
                              name={`${scbaSession.id}_${participation.firefighter.id}_endPressure`}
                              autoComplete="off"
                              placeholder="300-0"
                              required
                              onChange={(e) =>
                                updateValue(
                                  participation.firefighter.id,
                                  "endPressure",
                                  e.currentTarget.value
                                )
                              }
                              defaultValue={participation.endPressure ?? ""}
                              className="block w-32 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                            />
                          </td>
                          <td className="relative whitespace-nowrap py-3 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                            <Link
                              to={`${participation.firefighter.id}/delete`}
                              title="Teilnahme löschen"
                              preventScrollReset
                            >
                              <TrashIcon className="h-5 w-5 text-red-600 transition-colors hover:text-red-800" />
                              <span className="sr-only">
                                Löschen, {germanDate(scbaSession.date)}
                              </span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-row gap-4 py-4">
            <Link to="edit" preventScrollReset>
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600"
              >
                <PencilSquareIcon
                  className="-ml-0.5 h-5 w-5"
                  aria-hidden="true"
                />
                Belastungsübung bearbeiten
              </button>
            </Link>
            <Link to="delete" preventScrollReset>
              <button
                type="button"
                className="inline-flex items-center gap-x-1.5 rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
              >
                <TrashIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Belastungsübung löschen
              </button>
            </Link>
          </div>
        </ul>
      </div>
      <Outlet />
      <ConfirmNavigation blocker={blocker} />
    </>
  );
}
