import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "~/util/class-names";

export type CommandPaletteOption = {
  id: string;
  title: string;
  details?: string;
};

type CommandPaletteProps = {
  shouldClose?: boolean;
  placeholder?: string;
  options: CommandPaletteOption[];
  allowOnlyOneSelection?: boolean;
  onSelection?: (selectedOption: CommandPaletteOption) => any;
};

export default function CommandPalette(props: CommandPaletteProps) {
  const navigate = useNavigate();

  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => setOpen(true), []);

  useEffect(() => {
    if (props.shouldClose) {
      setOpen(false);
    }
  }, [props.shouldClose]);

  const filteredOptions =
    query === ""
      ? []
      : props.options.filter((option) => {
          return (
            option.title.toLowerCase().includes(query.toLowerCase()) ||
            option.details?.toLowerCase().includes(query.toLowerCase())
          );
        });

  return (
    <Transition.Root
      show={open}
      as={Fragment}
      afterLeave={() => {
        setQuery("");
        navigate("..", { preventScrollReset: true });
      }}
      appear
    >
      <Dialog
        as="div"
        className="relative z-10"
        onClose={!disabled ? setOpen : () => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox
                onChange={(selectedOption: CommandPaletteOption) => {
                  if (props.onSelection) {
                    props.onSelection(selectedOption);
                  }
                  if (props.allowOnlyOneSelection) {
                    setDisabled(true);
                  }
                }}
                disabled={disabled}
              >
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                    placeholder={
                      disabled ? "Lädt..." : props.placeholder ?? "Suchen..."
                    }
                    onChange={(event) => setQuery(event.target.value)}
                    autoFocus
                  />
                </div>

                {filteredOptions.length > 0 && (
                  <Combobox.Options
                    static
                    className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-gray-800"
                  >
                    {filteredOptions.map((option) => (
                      <Combobox.Option
                        key={option.id}
                        value={option}
                        className={({ active }) =>
                          classNames(
                            "cursor-default select-none px-4 py-2",
                            active && "bg-primary-600 text-white"
                          )
                        }
                      >
                        <div className="flex flex-wrap items-center justify-between">
                          <span className="font-semibold">{option.title}</span>
                          <span>{option.details}</span>
                        </div>
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== "" && filteredOptions.length === 0 && (
                  <p className="p-4 text-sm text-gray-500">Nichts gefunden.</p>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
