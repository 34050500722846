import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import SlideOver from "~/components/SlideOver";
import { ListAllFirefightersDocument } from "~/gql/graphql";
import { CreateFirefighterMutationDocument } from "~/mutations/CreateFirefighter.mutation";

type Inputs = {
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
};

export default function NewFirefighter() {
  const { register, handleSubmit } = useForm<Inputs>();
  const navigate = useNavigate();
  const [firefighterCreate, { loading, data, error }] = useMutation(
    CreateFirefighterMutationDocument,
    { refetchQueries: [ListAllFirefightersDocument] }
  );

  const submitHandler: SubmitHandler<Inputs> = (formData) => {
    firefighterCreate({ variables: { input: formData } });
  };

  useEffect(() => {
    if (!error && data && data.firefighterCreate.firefighter) {
      navigate(`/firefighters/${data.firefighterCreate.firefighter.id}`);
    }
  }, [data]);

  return (
    <SlideOver title="Neue Einsatzkraft anlegen">
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="space-y-6 pb-5 pt-6">
              <div>
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Vorname <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "Bitte geben Sie einen Vornamen ein"
                      }
                    })}
                    type="text"
                    id="firstName"
                    autoComplete="off"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="lastName"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Nachname <span className="font-light text-red-600">*</span>
                </label>
                <div className="mt-2">
                  <input
                    {...register("lastName", { required: true })}
                    type="text"
                    id="lastName"
                    autoComplete="off"
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                <label
                  htmlFor="dateOfBirth"
                  className="block text-sm font-medium text-gray-700"
                >
                  Geburtsdatum
                </label>
                <input
                  type="date"
                  id="dateOfBirth"
                  autoComplete="none"
                  {...register("dateOfBirth", { valueAsDate: true })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-1 flex-col justify-between">
          <div className="divide-y divide-gray-200 px-4 sm:px-6">
            <div className="pb-6 pt-4">
              <div className="text-sm text-gray-500">
                Mit <span className="font-light text-red-600">*</span>{" "}
                gekennzeichnete Felder sind Pflichtfelder.
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-shrink-0 justify-end px-4 py-4">
          <button
            type="submit"
            className="ml-4 inline-flex justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
            disabled={loading}
          >
            {loading ? "Wird angelegt..." : "Einsatzkraft anlegen"}
          </button>
        </div>
      </form>
    </SlideOver>
  );
}
