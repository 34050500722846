import { graphql } from "~/gql";

export const UpdateFirefighterMutationDocument = graphql(`
  mutation UpdateFirefighter($input: FirefighterUpdateInput!) {
    firefighterUpdate(input: $input) {
      firefighter {
        id
      }
    }
  }
`);
