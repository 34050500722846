import { graphql } from "~/gql";

export const InvitationDetailQueryDocument = graphql(`
  query InvitationDetail($invitationId: ID!) {
    invitation(id: $invitationId) {
      organization {
        name
      }
    }
  }
`);
