import { graphql } from "~/gql";

export const FirestationDetailQueryDocument = graphql(`
  query FirestationDetail(
    $firestationId: ID!
    $examinationType: ExaminationType!
  ) {
    firestation(id: $firestationId) {
      id
      name
      numberOfFirefighters
      numberOfDeployableFirefighters
      numberOfCompletedScbaSessionsThisYear
      firefighters {
        id
        firstName
        lastName
        firestations {
          name
        }
        lastScbaSessionParticipation {
          scbaSession {
            date
          }
        }
        hasValidExamination(type: $examinationType)
      }
      responsible {
        firstName
        lastName
        email
      }
    }
  }
`);
