import { graphql } from "~/gql";

export const ScbaSessionDetailQueryDocument = graphql(`
  query ScbaSessionDetail($scbaSessionId: ID!) {
    scbaSession(id: $scbaSessionId) {
      id
      comment
      date
      participations {
        initialPressure
        endPressure
        firefighter {
          id
          firstName
          lastName
        }
      }
    }
  }
`);
