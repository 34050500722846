import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  concat
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import React, { PropsWithChildren, ReactElement } from "react";
import { useAuth } from "~/hooks/useAuth";

export default function GraphQLProvider({
  children
}: PropsWithChildren): ReactElement {
  const httpLink = new HttpLink({ uri: import.meta.env.VITE_GRAPHQL_ENDPOINT });
  const { getAccessToken } = useAuth();

  const client = React.useMemo(() => {
    const authLink = setContext(async (_, { headers }) => {
      const token = await getAccessToken();

      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ""
        }
      };
    });

    return new ApolloClient({
      link: concat(authLink, httpLink),
      cache: new InMemoryCache()
    });
  }, [getAccessToken]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
