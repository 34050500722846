import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import CommandPalette, {
  CommandPaletteOption
} from "~/components/CommandPalette";
import { FirefighterDetailDocument, GroupDetailDocument } from "~/gql/graphql";
import { CreateGroupMembershipMutationDocument } from "~/mutations/CreateGroupMembership.mutation";
import { AddFirefighterToGroupQueryDocument } from "~/queries/AddFirefighterToGroup.query";
import { extractCode } from "~/util/error";

export default function AddFirefighterToGroup() {
  const params = useParams();
  const groupId = params.groupId as string;

  const [shouldClose, setShouldClose] = useState(false);

  const { error: queryError, data: queryData } = useQuery(
    AddFirefighterToGroupQueryDocument,
    {
      variables: { groupId }
    }
  );

  useEffect(() => {
    if (queryError) {
      const code = extractCode(queryError);
      switch (code) {
        case "FORBIDDEN":
          toast.error("Sie sind nicht berechtigt, Einsatzkräfte hinzuzufügen.");
          break;
        default:
          toast.error("Unerwarteter Fehler beim Abrufen der Einsatzkräfte.");
          break;
      }
      setShouldClose(true);
    }
  }, [queryError]);

  const firefightersAlreadyMembers = (queryData?.group?.members ?? []).map(
    (m) => m.id
  );

  const filteredFirefighters = (queryData?.firefighters ?? []).filter(
    (f) => !firefightersAlreadyMembers.includes(f.id)
  );

  const options: CommandPaletteOption[] = filteredFirefighters.map(
    (firefighter) => {
      const firestationSuffix = firefighter.firestations
        .map((firestation) => firestation.name)
        .join(", ");

      return {
        id: firefighter.id,
        title: `${firefighter.lastName}, ${firefighter.firstName}`,
        details: firestationSuffix
      };
    }
  );

  const [addFirefighterToSession] = useMutation(
    CreateGroupMembershipMutationDocument
  );

  const handleSelection = (option: CommandPaletteOption) => {
    const firefighterId = option.id;

    addFirefighterToSession({
      variables: { input: { groupId, firefighterId } },
      refetchQueries: [
        { query: GroupDetailDocument, variables: { groupId } },
        {
          query: FirefighterDetailDocument,
          variables: { firefighterId }
        }
      ]
    })
      .catch(() => toast.error("Fehler beim Hinzufügen der Einsatzkraft"))
      .finally(() => setShouldClose(true));
  };

  return (
    <CommandPalette
      options={options}
      onSelection={handleSelection}
      shouldClose={shouldClose}
      allowOnlyOneSelection
      placeholder="Einsatzkraft suchen..."
    />
  );
}
