import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";

export const useAuth = () => {
  const {
    login,
    createAccount,
    logout,
    getAccessToken,
    refreshClaims,
    claims
  } = useContext(AuthContext);
  return {
    login,
    createAccount,
    logout,
    getAccessToken,
    refreshClaims,
    claims
  };
};
