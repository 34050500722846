import { graphql } from "~/gql";

export const UserDetailQueryDocument = graphql(`
  query UserDetail($userId: ID!) {
    user(id: $userId) {
      email
      name
      roles
    }
  }
`);
